
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import { APP_NAME, COMMISSION } from "../constants";
import { logoutService } from "../services/LogoutService";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import LogoutModel from "./LogoutModel";
import { generateMerchantAgreementDocument, preGenerateAgreement } from "../services/Api";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Input component for displaying static fields
const InputField = ({ label, value, id }) => (
  <div>
    <label htmlFor={id} className="block mt-5 text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type="text"
      id={id}
      value={value}
      readOnly
      className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
    />
  </div>
);

// const Contract = () => {
//   const navigate = useNavigate();
//   const [state, setState] = useState({
//     showModal: false,
//     showReferModal: false,
//     showLogoutModal: false,
//     restaurantName: localStorage.getItem("restaurantName"),
//     ownerName: localStorage.getItem("ownerName"),
//     businessAddress: localStorage.getItem("businessAddress"),
//     gstNumber: localStorage.getItem("gstNumber"),
//     bankDetails: localStorage.getItem("accountNumber"),
//     fssaiDetails: localStorage.getItem("fssaiNumber"),
//     isChecked: false,
//     isFormValid: false,
//     restaurantId: localStorage.getItem("restaurant_Id"),
//   });

//   // UseEffect to retrieve data from local storage and check token
//   useEffect(() => {
//     const token = localStorage.getItem("accessToken");
//     const restaurantInfo = JSON.parse(localStorage.getItem("restaurantInfoData"));

//     if (!token) {
//       navigate("/");
//     } else if (restaurantInfo) {
//       setState((prevState) => ({
//         ...prevState,
//         restaurantName: restaurantInfo.restaurant_name,
//         ownerName: restaurantInfo.owner_full_name,
//       }));
//     }
//   }, [navigate]);


//   // Updated callPreGenerateAgreement Function
//   const callPreGenerateAgreement = async () => {
//     try {
//       const response = await preGenerateAgreement(state.restaurantId);

//       if (response?.requestMetaDataId) {
//         localStorage.setItem("AgreementMetaDataId", response.requestMetaDataId);

//         const documentResponse = await generateMerchantAgreementDocument(response.requestMetaDataId);

//         if (documentResponse?.done === "signature" && documentResponse?.document_path) {
//           localStorage.setItem("documentUrl", documentResponse.document_path);
//           navigate("/sign-document");
//         }
//       } else {
//         console.error("No requestMetaDataId found in the response");
//       }
//     } catch (error) {
//       console.error("Error in Pre-Generate Agreement:", error);
//       throw error;
//     }
//   };



//   // Handle checkbox to enable/disable the Proceed button
//   const handleCheckboxChange = () => {
//     setState((prevState) => ({
//       ...prevState,
//       isChecked: !prevState.isChecked,
//       isFormValid: !prevState.isChecked,
//     }));
//   };

//   // Handle "Proceed to Sign" button click
//   const handleNextClick = () => {
//     if (state.isFormValid) {
//       callPreGenerateAgreement();
//       navigate("/sign-document");
//       // Trigger the first API on button click
//     }
//   };

//   // Confirm and execute logout
//   const confirmLogout = () => {
//     toggleModal("showLogoutModal");
//     logoutService("Session");
//   };

//   // Toggle modal visibility
//   const toggleModal = (modal) => {
//     setState((prevState) => ({ ...prevState, [modal]: !prevState[modal] }));
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col">
//       <main className="flex-grow flex pt-20 pb-20 w-full">
//         <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1 sm:px-6">
//           <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>

//           {/* Display Input Fields */}
//           <InputField label="Restaurant Name*" value={state.restaurantName} id="restaurantName" />
//           <InputField label="Owner Name*" value={state.ownerName} id="ownerName" />
//           <InputField label="Business Address" value={state.businessAddress} id="businessAddress" />
//           <InputField label="GST Number" value={state.gstNumber} id="gstNumber" />
//           <InputField label="Bank Account Details" value={state.bankDetails} id="bankDetails" />
//           <InputField label="FSSAI Details" value={state.fssaiDetails} id="fssaiDetails" />

//           <p className="mt-5 text-xl sm:text-2xl font-bold text-center sm:text-left">
//             In {APP_NAME}, we are providing very low commission, only {COMMISSION}
//           </p>

//           {/* Terms and Conditions Checkbox */}
//           <form className="mt-5">
//             <div className="checkbox-container flex items-center">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms"
//                 className="mr-2"
//                 checked={state.isChecked}
//                 onChange={handleCheckboxChange}
//               />
//               <label htmlFor="terms" className="text-sm">
//                 I agree to the <a href="#" className="text-blue-500 underline">terms and conditions</a>
//               </label>
//             </div>
//           </form>

//           {/* Navigation Buttons */}
//           <div className="flex flex-col sm:flex-row mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
//             <button
//               className="text-white text-center rounded px-5 py-2 bg-green-600"
//               onClick={() => navigate("/restaurant-documents")}
//             >
//               Back
//             </button>

//             <button
//               onClick={handleNextClick}
//               disabled={!state.isFormValid}
//               className={`text-white text-center rounded px-5 py-2 ${state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>

//       {/* Modal Components */}
//       {state.showModal && <RequiredRegistrationDetails toggleModal={() => toggleModal("showModal")} />}
//       {state.showReferModal && (
//         <ReferrerModal
//           toggleReferModal={() => toggleModal("showReferModal")}
//           onClickSuccess={() => toggleModal("showReferModal")}
//         />
//       )}
//       {state.showLogoutModal && (
//         <LogoutModel
//           onClickCancel={confirmLogout}
//           onClickLogout={() => toggleModal("showLogoutModal")}
//         />
//       )}
//     </div>
//   );
// };

const Contract = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    showModal: false,
    showReferModal: false,
    showLogoutModal: false,
    restaurantName: localStorage.getItem("restaurantName"),
    ownerName: localStorage.getItem("ownerName"),
    businessAddress: localStorage.getItem("businessAddress"),
    gstNumber: localStorage.getItem("gstNumber"),
    bankDetails: localStorage.getItem("accountNumber"),
    fssaiDetails: localStorage.getItem("fssaiNumber"),
    isChecked: false,
    isFormValid: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
  });
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simulate a loading period (e.g., for fetching data)
    const timeout = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);



  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) navigate("/");
  }, [navigate]);

  // Handle the "Proceed to Sign" action
  const callPreGenerateAgreement = async () => {
    try {
      const response = await preGenerateAgreement(state.restaurantId);
      if (response?.requestMetaDataId) {
        // Save metadata ID locally
        localStorage.setItem("AgreementMetaDataId", response.requestMetaDataId);

        const documentResponse = await generateMerchantAgreementDocument(response.requestMetaDataId);
        console.log(documentResponse);
        if (documentResponse?.done === "signature" || documentResponse?.document_path) {
          // Save document path and navigate to sign document page
          localStorage.setItem("documentUrl", documentResponse.document_path);
          navigate("/sign-document");
        } else {
          console.error("Document generation failed or incomplete");
        }
      } else {
        console.error("No requestMetaDataId in response");
      }
    } catch (error) {
      console.error("Error in Pre-Generate Agreement:", error);
    }
  };

  const handleCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      isChecked: !prevState.isChecked,
      isFormValid: !prevState.isChecked,
    }));
  };

  const handleNextClick = () => {
    if (state.isFormValid) {
      callPreGenerateAgreement();
    }
  };

  return (
    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f7f7f7">

      <div className="min-h-screen bg-gray-100 flex flex-col">
        <main className="flex-grow flex pt-20 pb-20 w-full">
          <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1 sm:px-6">

            {loading ? (<Skeleton className="mt-3" height={50} width={650}></Skeleton>) : (
              <>
                <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>
              </>
            )
            }
            {/* Input Fields */}
            {loading ? (<Skeleton className="mt-3" height={450} width={950}></Skeleton>) : (
              <>
                <InputField label="Restaurant Name*" value={state.restaurantName} id="restaurantName" />
                <InputField label="Owner Name*" value={state.ownerName} id="ownerName" />
                <InputField label="Business Address" value={state.businessAddress} id="businessAddress" />
                <InputField label="GST Number" value={state.gstNumber} id="gstNumber" />
                <InputField label="Bank Account Details" value={state.bankDetails} id="bankDetails" />
                <InputField label="FSSAI Details" value={state.fssaiDetails} id="fssaiDetails" />

                <p className="mt-5 text-xl sm:text-2xl font-bold text-center sm:text-left">
                  In {APP_NAME}, we are providing very low commission, only {COMMISSION}
                </p>
              </>
            )}
            {/* Checkbox */}

            <form className="mt-5">
              {loading ? (<Skeleton className="mt-3" height={50} width={250}></Skeleton>) : (
                <>

                  <div className="checkbox-container flex items-center">
                    <input
                      type="checkbox"
                      id="terms"
                      name="terms"
                      className="mr-2"
                      checked={state.isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="terms" className="text-sm">
                      I agree to the <a href="#" className="text-blue-500 underline">terms and conditions</a>
                    </label>
                  </div>
                </>
              )}
            </form>

            {/* Navigation Buttons */}
            <div className="flex flex-col sm:flex-row mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
              <button
                className="text-white text-center rounded px-5 py-2 bg-green-600"
                onClick={() => navigate("/restaurant-documents")}
              >
                Back
              </button>

              <button
                onClick={handleNextClick}
                disabled={!state.isFormValid}
                className={`text-white text-center rounded px-5 py-2 ${state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
              >
                Proceed to Sign
              </button>
            </div>

          </div>

        </main>
      </div>

    </SkeletonTheme >
  );
};


export default Contract;
