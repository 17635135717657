
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Registration from "./components/Registration";
import RestaurantDocuments from "./components/RestaurantDocuments";
import MenuOperational from "./components/MenuOperational";
import Contract from "./components/Contract";
import SignDocument from "./components/SignDocument";
import CompleteSign from "./components/CompleteSign";
import RedirectToLastPage from "./utils/RedirectToLastPage";
import TrackLastPage from "./utils/TrackLastPage";
import Layout from "./components/Layout";
import useNetworkStatus from "./components/networkStatus"; // Import custom hook
import NetworkErrorPage from "./components/NerworkErrorPage"; // Import error page
import { fetchRemoteConfig, logPageView } from "./services/firebase";


// Track user navigation inside the app
const TrackPageViews = () => {
  const location = useLocation();

  useEffect (() => {
    logPageView(location.pathname); // Log page visits
  }, [location]);

  return null;
};

function App() {
  const isOnline = useNetworkStatus(); // Use network status hook

  useEffect(() => {
    fetchRemoteConfig(); // Fetch Remote Config on app start
  }, []);

  if (!isOnline) {
    // If offline, render the NetworkErrorPage
    return <NetworkErrorPage />;
  }

  return (
    <Router>
      <RedirectToLastPage />
      <TrackLastPage />
      <TrackPageViews /> {/* Logs page visits */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Layout />}>
          <Route path="/register" element={<Registration />} />
          <Route path="/menu-operational" element={<MenuOperational />} />
          <Route path="/restaurant-documents" element={<RestaurantDocuments />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/sign-document" element={<SignDocument />} />
          <Route path="/complete-sign" element={<CompleteSign />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
