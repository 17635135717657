import React from 'react';
import pancard from "../images/pan-card.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const UploadGuidelinesModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="fixed inset-0" onClick={onClose}></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl w-full max-w-lg mx-4 lg:mx-0">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-bold">Image upload guidelines</h2>
          <button className="cursor-pointer text-2xl" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} className="cursor-pointer" />
          </button>
        </div>
        <div className="p-4">
          <ul className="space-y-4">
            <li className="flex items-center space-x-2">
              <img
                src={pancard}
                alt="Guideline 1"
                className="w-24 h-20 lg:w-32 lg:h-24 object-cover"
              />
              <span className="text-sm lg:text-base">
                Make sure all details <br /> are visible.
              </span>
            </li>
            <li className="flex items-center space-x-2">
              <img
                src={pancard}
                alt="Guideline 2"
                className="w-24 h-20 lg:w-32 lg:h-24 object-cover"
              />
              <span className="text-sm lg:text-base">Image should not be blurry.</span>
            </li>
            <li className="flex items-center space-x-2">
              <img
                src={pancard}
                alt="Guideline 3"
                className="w-24 h-20 lg:w-32 lg:h-24 object-cover"
              />
              <span className="text-sm lg:text-base">Image should not be cropped.</span>
            </li>
          </ul>
        </div>
        <div className="p-4 border-t">
          <button
            className="bg-green-500 text-white cursor-pointer px-4 py-2 w-full rounded lg:w-auto"
            onClick={onClose}
          >
            Upload now
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadGuidelinesModal;
