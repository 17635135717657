/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { confirmDeliveryMenuImageUpload, confirmFoodImageUpload, confirmProfileImageUpload, confirmStoreImageUpload, fetchActiveSearchCategories, uploadDeliveryMenuImage, uploadFoodImage, uploadProfileImage, uploadStoreImage, fetchDeliveryMenuImages, fetchFoodImages, fetchProfileImage, fetchStoreImages, deleteDeliveryMenuImage, deleteFoodImage, deleteStoreImage, deleteProfileImage, } from "../services/Api"; // Import the API functions
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import { faUpload, faChevronDown, faPlus, } from "@fortawesome/free-solid-svg-icons";
import RestaurantImages from "./RestaurantImages";
import FoodImages from "./FoodImages";
import DeliveryMenu from "./DeliveryMenu";
import RestaurentProfileImages from "./RestaurentProfileImages";
import imageCompression from "browser-image-compression"; // Import image compression library
import example from "../images/restaurantt.png";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { updateMenuOperation, readMenuOperation } from "../services/Api";
import { handleError } from "../services/errorHandler";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MenuOperational = () => {

  const [state, setState] = useState({
    showModal: false,
    showReferModal: false,
    showLogoutConfirm: false,
    isRestaurentModalOpen: false,
    isFoodModalOpen: false,
    isDelieveryMenuOpen: false,
    isProfileImageOpen: false,
    restaurantImages: [],
    foodImages: [],
    deliveryMenuImages: [],
    profileImage: null,
    showAll: false,
    searchQuery: "",
    selectedCuisines: [],
    errorMessage: "",
    cuisines: [],
    isFormValid: false,
    showLogoutOptions: false,
    showLogoutModal: null,
    restaurantId: localStorage.getItem("restaurant_Id"),
    is24x7Open: false, // New state for 24/7 checkbox
    days: {
      Monday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Tuesday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Wednesday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Thursday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Friday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Saturday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Sunday: { slots: [{ open: 9, close: 21 }], enabled: false },
    },
    diningHoursSameAsDelivery: false,
    is24x7DiningOpen: false,
    diningDays: {
      Monday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Tuesday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Wednesday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Thursday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Friday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Saturday: { slots: [{ open: 9, close: 21 }], enabled: true },
      Sunday: { slots: [{ open: 9, close: 21 }], enabled: true },
    },
    selectedDevices: [], // Updated state to hold multiple device types
    today: new Date().toLocaleString("en-US", { weekday: "long" }),
  });

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Simulate a loading period (e.g., for fetching data)
    const timeout = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedMenuData = localStorage.getItem("parsedMenuData");
    if (savedMenuData) {
      const parsedMenuData = JSON.parse(savedMenuData);
      // Populate the state with the saved data
      setState((prevState) => ({
        ...prevState,
        restaurantImages: parsedMenuData.restaurantImages || [],
        foodImages: parsedMenuData.foodImages || [],
        deliveryMenuImages: parsedMenuData.deliveryMenuImages || [],
        profileImage: parsedMenuData.profileImage || null,
        selectedCuisines: parsedMenuData.selectedCuisines || [],
        is24x7Open: parsedMenuData.is24x7Open || false,
        days: parsedMenuData.days || prevState.days,
        is24x7DiningOpen: parsedMenuData.is24x7DiningOpen || false,
        diningDays: parsedMenuData.diningDays || prevState.diningDays,
        selectedDevices: parsedMenuData.selectedDevices || [],
      }));
    }
  }, []);

  useEffect(() => {
    const savedResDocsData = localStorage.getItem("restaurantDocumentsData");
    if (savedResDocsData) {
      const parsedData = JSON.parse(savedResDocsData);
      localStorage.setItem("parsedResDocsData", JSON.stringify(parsedData))
    }
  }, []);

  useEffect(() => {
    validateForm();
  }, [state.restaurantImages, state.foodImages, state.deliveryMenuImages, state.profileImage, state.selectedCuisines, state.days, state.diningDays, state.selectedDevices]);

  // Save data on unmount (e.g., when navigating away)
  useEffect(() => {
    const menuOperationData = {
      restaurantImages: state.restaurantImages,
      foodImages: state.foodImages,
      deliveryMenuImages: state.deliveryMenuImages,
      profileImage: state.profileImage,
      selectedCuisines: state.selectedCuisines,
      is24x7Open: state.is24x7Open,
      days: state.days,
      is24x7DiningOpen: state.is24x7DiningOpen,
      diningDays: state.diningDays,
      selectedDevices: state.selectedDevices || [],
    };
    localStorage.setItem("menuOperationData", JSON.stringify(menuOperationData));
  }, [state.restaurantImages, state.foodImages, state.deliveryMenuImages, state.profileImage, state.selectedCuisines, state.is24x7Open, state.days, state.is24x7DiningOpen, state.diningDays, state.selectedDevices]);

  // Days Slots Function
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      days: {
        ...prevState.days,
        [prevState.today]: { ...prevState.days[prevState.today], enabled: true },
      },
    }));
  }, [state.today]);

  // Search Cuisines Categories function
  const fetchCategoriesWithToken = async () => {
    try {
      try {
        const categories = await fetchActiveSearchCategories();
        setState((prevState) => ({ ...prevState, cuisines: categories }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const categories = await fetchActiveSearchCategories();
          setState((prevState) => ({ ...prevState, cuisines: categories }));
        } else {
          console.error("Error fetching categories:", error);
        }
      }
    } catch (error) {
      console.error("Error handling tokens or fetching categories:", error);
    }
  };

  // Redirect function if user not signed in, redirect to login
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  // Fetch All Images
  useEffect(() => {
    fetchCategoriesWithToken();
    fetchAllImages(); // Fetch all images on component mount
  }, []);

  // All Iamge fetching function (e.g food img, delivery img, resturant img)
  const fetchAllImages = async () => {
    try {
      const [restaurant, food, deliveryMenu, profile] = await Promise.all([
        fetchStoreImages(state.restaurantId),
        fetchFoodImages(state.restaurantId),
        fetchDeliveryMenuImages(state.restaurantId),
        fetchProfileImage(state.restaurantId)
      ]);

      setState((prevState) => ({
        ...prevState,
        restaurantImages: restaurant || [],
        foodImages: food || [],
        deliveryMenuImages: deliveryMenu || [],
        profileImage: profile || null,
      }));
    } catch (error) {
      console.error("Error fetching images:", error);
      alert("Failed to fetch images. Please try again.");
    }
  };

  // Image Upload Function
  const handleImageUpload = async (
    e,
    uploadFunction,
    confirmUploadFunction,
    setImagesField,
    isProfileImage = false // New parameter to distinguish profile image
  ) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      const validSize = 5 * 1024 * 1024; // 5MB
      return validTypes.includes(file.type) && file.size <= validSize;
    });

    if (validFiles.length !== files.length) {
      alert("Some files were not added due to invalid type or size.");
    }

    for (const file of validFiles) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          fileType: "image/webp",
        };

        const webpFile = await imageCompression(file, options);

        const { presignedURL, metadataId } = await uploadFunction(
          "webp",
          state.restaurantId
        );

        await fetch(presignedURL, {
          method: "PUT",
          headers: {
            "Content-Type": "image/webp",
          },
          body: webpFile,
        });

        const response = await confirmUploadFunction(metadataId, true, state.restaurantId);
        const publicUrl = response.publicUrl;

        if (isProfileImage) {
          // Handle profile image as a single string
          setState((prevState) => ({
            ...prevState,
            profileImage: publicUrl, // Directly set the profile image URL
          }));
        } else {
          // For other images, treat them as arrays
          setState((prevState) => ({
            ...prevState,
            [setImagesField]: [...prevState[setImagesField], publicUrl], // Use Set to remove duplicates
          }));
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload the image");
      }
    }
  };

  const handleImageChange = async (e, uploadFunction, confirmFunction, field) => {
    await handleImageUpload(e, uploadFunction, confirmFunction, field);
  };

  // Restaurant Image Change
  const handleRestaurantImageChange = async (e) => {
    await handleImageChange(e, uploadStoreImage, confirmStoreImageUpload, "restaurantImages");
  }

  // Resturant Food Image Change
  const handleFoodImageChange = (e) =>
    handleImageChange(e, uploadFoodImage, confirmFoodImageUpload, "foodImages");

  // Delivery Menu Image Change
  const handleDeliveryMenuImageChange = (e) =>
    handleImageChange(e, uploadDeliveryMenuImage, confirmDeliveryMenuImageUpload, "deliveryMenuImages");

  // Profile Image Upload function
  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const validSize = 5 * 1024 * 1024; // 5MB

    if (!validTypes.includes(file.type) || file.size > validSize) {
      alert("Invalid file type or size.");
      return;
    }

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        fileType: "image/webp",
      };

      const webpFile = await imageCompression(file, options);
      const { presignedURL, metadataId } = await uploadProfileImage("webp", state.restaurantId);

      await fetch(presignedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "image/webp",
        },
        body: webpFile,
      });

      const response = await confirmProfileImageUpload(metadataId, true, state.restaurantId);
      const publicUrl = response.publicUrl;

      setState((prevState) => ({
        ...prevState,
        profileImage: publicUrl, // Directly set the profile image URL
      }));
    } catch (error) {
      console.error("Error uploading profile image:", error);
      alert("Failed to upload the profile image");
    }
  };

  // Profile Image Delete function
  const handleProfileImageDelete = async () => {
    try {
      await deleteProfileImage(state.profileImage, state.restaurantId);
      setState((prevState) => ({
        ...prevState,
        profileImage: null, // Remove the profile image
      }));
    } catch (error) {
      console.error("Error deleting profile image:", error);
      alert("Failed to delete profile image. Please try again.");
    }
  };

  // Day Change function
  const handleDayChange = (day) => {
    setState((prevState) => ({
      ...prevState,
      days: {
        ...prevState.days,
        [day]: { ...prevState.days[day], enabled: !prevState.days[day].enabled },
      },
    }));
  };

  // Time changes function
  const handleTimeChange = (day, index, type, value) => {
    setState((prevState) => {
      const newSlots = [...prevState.days[day].slots];
      newSlots[index][type] = value;
      return {
        ...prevState,
        days: { ...prevState.days, [day]: { ...prevState.days[day], slots: newSlots } },
      };
    });
  };

  // Add time Slot Function
  const addTimeSlot = (day) => {
    setState((prevState) => ({
      ...prevState,
      days: {
        ...prevState.days,
        [day]: {
          ...prevState.days[day],
          slots: [...prevState.days[day].slots, { open: 9, close: 21 }],
        },
      },
    }));
  };

  // Delete image function
  const handleImageDelete = async (imageType, imageUrl) => {
    try {
      switch (imageType) {
        case "restaurant":
          await deleteStoreImage(imageUrl, state.restaurantId);
          setState((prevState) => ({
            ...prevState,
            restaurantImages: prevState.restaurantImages.filter((img) => img !== imageUrl),
          }));
          break;
        case "food":
          await deleteFoodImage(imageUrl, state.restaurantId);
          setState((prevState) => ({
            ...prevState,
            foodImages: prevState.foodImages.filter((img) => img !== imageUrl),
          }));
          break;
        case "deliveryMenu":
          await deleteDeliveryMenuImage(imageUrl, state.restaurantId);
          setState((prevState) => ({
            ...prevState,
            deliveryMenuImages: prevState.deliveryMenuImages.filter((img) => img !== imageUrl),
          }));
          break;
        default:
          console.error("Unknown image type for deletion");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  // Logout button function
  const handleLogoutClick = (type) => {
    setState((prevState) => ({
      ...prevState,
      showLogoutOptions: false,
      showLogoutModal: false,
    }));
    logoutService("Session");
  };

  // Cuisine Selection Function
  const handleCuisineClick = (cuisine) => {
    const alreadySelected = state.selectedCuisines.includes(cuisine);
    if (alreadySelected) {
      setState((prevState) => ({
        ...prevState,
        selectedCuisines: prevState.selectedCuisines.filter((item) => item !== cuisine),
      }));
    } else {
      if (state.selectedCuisines.length < 10) {
        setState((prevState) => ({
          ...prevState,
          selectedCuisines: [...prevState.selectedCuisines, cuisine],
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          errorMessage: "You can only select up to 10 cuisines.", // Set error message
        }));
        // Clear the error message after 3 seconds
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            errorMessage: "", // Clear error message after 3 seconds
          }));
        }, 5000); // 5 seconds
      }
    }
  };

  //  Validate function for cuisines selected and dinning day
  const validateForm = () => {
    const hasValidDeliveryTime =
      state.is24x7Open || // If 24x7 is enabled, skip checking days
      (Object.values(state.days).some((day) => day.enabled) && // Otherwise, check if at least one delivery day is selected
        Object.values(state.days).every((day) =>
          day.slots.every((slot) => slot.open !== null && slot.close !== null)
        ));

    const hasValidDiningTime =
      state.is24x7DiningOpen || // If 24x7 dining is enabled, skip checking dining days
      (Object.values(state.diningDays).some((day) => day.enabled) && // Otherwise, check if at least one dining day is selected
        Object.values(state.diningDays).every((day) =>
          day.slots.every((slot) => slot.open !== null && slot.close !== null)
        ));

    const isvalid =
      state.restaurantImages.length > 0 &&
      state.foodImages.length > 0 &&
      state.deliveryMenuImages.length > 0 &&
      state.profileImage &&
      state.selectedCuisines.length > 0 &&
      state.selectedCuisines.length <= 10 &&
      state.selectedDevices &&
      hasValidDeliveryTime &&
      hasValidDiningTime;

    setState((prev) => ({
      ...prev,
      isFormValid: isvalid
    }))
  };


  const filteredCuisines = state.cuisines.filter((cuisine) =>
    cuisine.toLowerCase().includes(state.searchQuery.toLowerCase())
  );

  // Required Registration Details toggle modal
  const toggleModal = () => setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }));

  // Referrer Modal Toogle modal
  const toggleReferModal = () => setState((prevState) => ({ ...prevState, showReferModal: !prevState.showReferModal }));

  // Restaurant dining hour check box function
  const handleDiningHoursCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      diningHoursSameAsDelivery: !prevState.diningHoursSameAsDelivery,
      diningDays: prevState.diningHoursSameAsDelivery
        ? { ...prevState.days }
        : {
          Monday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Tuesday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Wednesday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Thursday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Friday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Saturday: { slots: [{ open: 9, close: 21 }], enabled: true },
          Sunday: { slots: [{ open: 9, close: 21 }], enabled: true },
        },
    }));
  };

  // Restaurant Dining time change function
  const handleDiningTimeChange = (day, index, type, hour) => {
    setState((prevState) => {
      const newSlots = [...prevState.diningDays[day].slots];
      newSlots[index][type] = hour;
      return {
        ...prevState,
        diningDays: {
          ...prevState.diningDays,
          [day]: { ...prevState.diningDays[day], slots: newSlots },
        },
      };
    });
  };

  // Restaurant Dining day change function
  const handleDiningDayChange = (day) => {
    setState((prevState) => ({
      ...prevState,
      diningDays: {
        ...prevState.diningDays,
        [day]: { ...prevState.diningDays[day], enabled: !prevState.diningDays[day].enabled },
      },
    }));
  };

  // 24/7 Checkbox change function for Delivery Time
  const handle24x7Change = () => {
    setState((prevState) => {
      const is24x7Open = !prevState.is24x7Open;
      const updatedDays = Object.keys(prevState.days).reduce((acc, day) => {
        acc[day] = {
          slots: [{ open: 0, close: 24 }], // Set time from 12:00 AM to 12:00 AM next day
          enabled: is24x7Open,
        };
        return acc;
      }, {});

      return {
        ...prevState,
        is24x7Open,
        days: updatedDays, // Update days for 24/7 setting
      };
    });
  };

  // 24/7 Checkbox change function for Dining Time
  const handle24x7DiningChange = () => {
    setState((prevState) => {
      const is24x7DiningOpen = !prevState.is24x7DiningOpen;
      const updatedDiningDays = Object.keys(prevState.diningDays).reduce((acc, day) => {
        acc[day] = {
          slots: [{ open: 0, close: 24 }],
          enabled: is24x7DiningOpen,
        };
        return acc;
      }, {});

      return {
        ...prevState,
        is24x7DiningOpen,
        diningDays: updatedDiningDays, // Update dining days for 24/7 setting
      };
    });
  };


  // Fetch required data from the state to store as a draft
  // const handleSaveDraft = () => {
  //   validateForm()

  //   const menuOperationData = {
  //     cuisineSelections: state.selectedCuisines.join(", "), // Join cuisines into a comma-separated string
  //     restaurantDeliveryTimeWindowIs247: state.is24x7Open, // 24/7 delivery time flag
  //     restaurantDeliveryTimeWindow: Object.keys(state.days)
  //       .filter((day) => state.days[day].enabled)
  //       .map((day) => ({
  //         dayOfTheWeek: day,
  //         windowStart: `${state.days[day].slots[0].open}:00:00`,
  //         windowEnd: `${state.days[day].slots[0].close}:00:00`,
  //       })),
  //     restaurantTakeawayTimeWindowIs247: state.is24x7DiningOpen, // 24/7 takeaway time flag
  //     restaurantTakeawayTimeWindow: Object.keys(state.diningDays)
  //       .filter((day) => state.diningDays[day].enabled)
  //       .map((day) => ({
  //         dayOfTheWeek: day,
  //         windowStart: `${state.diningDays[day].slots[0].open}:00:00`,
  //         windowEnd: `${state.diningDays[day].slots[0].close}:00:00`,
  //       })),
  //     devicesOpted: state.selectedDevices ? [state.selectedDevices] : [], // Devices opted for order acceptance
  //   };
  // };



  const handleSaveDraft = () => {
    // Validate form before saving
    validateForm();

    // Construct the data object to save
    const menuOperationData = {
      cuisineSelections: state.selectedCuisines.join(", "), // Join cuisines into a comma-separated string
      restaurantDeliveryTimeWindowIs247: state.is24x7Open, // 24/7 delivery time flag
      restaurantDeliveryTimeWindow: Object.keys(state.days)
        .filter((day) => state.days[day].enabled)
        .map((day) => ({
          dayOfTheWeek: day,
          windowStart: `${state.days[day].slots[0].open}:00:00`,
          windowEnd: `${state.days[day].slots[0].close}:00:00`,
        })),
      restaurantTakeawayTimeWindowIs247: state.is24x7DiningOpen, // 24/7 takeaway time flag
      restaurantTakeawayTimeWindow: Object.keys(state.diningDays)
        .filter((day) => state.diningDays[day].enabled)
        .map((day) => ({
          dayOfTheWeek: day,
          windowStart: `${state.diningDays[day].slots[0].open}:00:00`,
          windowEnd: `${state.diningDays[day].slots[0].close}:00:00`,
        })),
      devicesOpted: state.selectedDevices ? [state.selectedDevices] : [], // Devices opted for order acceptance
      devicesOpted: state.selectedDevices ? [state.selectedDevices] : [], // Devices opted for order acceptance
    };

    // Save to localStorage
    localStorage.setItem("restaurantDraft", JSON.stringify(menuOperationData));

    // Set a success message
    setSaveMessage("Details saved successfully!");

    // Clear the message after a delay (optional, for better UX)
    setTimeout(() => {
      setSaveMessage("");
    }, 3000); // Clear after 3 seconds
  };


  const [saveMessage, setSaveMessage] = useState("");



  const fetchSavedDraft = () => {
    const savedData = localStorage.getItem("restaurantDraft");
    if (savedData) {
      const parsedData = JSON.parse(savedData);

      // Set the saved data back to state (if needed)
      setState((prevState) => ({
        ...prevState,
        selectedCuisines: parsedData.cuisineSelections.split(", "),
        is24x7Open: parsedData.restaurantDeliveryTimeWindowIs247,
        days: parsedData.restaurantDeliveryTimeWindow.reduce((acc, item) => {
          acc[item.dayOfTheWeek] = {
            enabled: true,
            slots: [
              {
                open: item.windowStart.split(":")[0], // Extract hours
                close: item.windowEnd.split(":")[0],
              },
            ],
          };
          return acc;
        }, {}),
        is24x7DiningOpen: parsedData.restaurantTakeawayTimeWindowIs247,
        diningDays: parsedData.restaurantTakeawayTimeWindow.reduce((acc, item) => {
          acc[item.dayOfTheWeek] = {
            enabled: true,
            slots: [
              {
                open: item.windowStart.split(":")[0],
                close: item.windowEnd.split(":")[0],
              },
            ],
          };
          return acc;
        }, {}),
        selectedDevices: parsedData.devicesOpted[0] || null,
      }));
    } else {
      alert("No draft data found!");
    }
  };





  // Handle device type selection
  // const handleDeviceSelect = (device) => {
  //   setState((prevState) => {
  //     // const isDeviceSelected = prevState.selectedDevices.includes(device);
  //     const isDeviceSelected = (device) =>
  //       Array.isArray(state.selectedDevices) && state.selectedDevices.includes(device);
  //     const updatedDevices = isDeviceSelected
  //       ? prevState.selectedDevices.filter((d) => d !== device)
  //       : [...prevState.selectedDevices, device];
  //     return { ...prevState, selectedDevices: updatedDevices };
  //   });
  // };


  const handleDeviceSelect = (device) => {
    setState((prevState) => {
      const isSelected = Array.isArray(prevState.selectedDevices)
        ? prevState.selectedDevices.includes(device)
        : false;

      const updatedDevices = isSelected
        ? prevState.selectedDevices.filter((d) => d !== device)
        : [...(prevState.selectedDevices || []), device];

      return { ...prevState, selectedDevices: updatedDevices };
    });
  };


  // Function Next button
  const handleNextClick = async () => {
    if (state.isFormValid) {
      const menuOperationData = {
        cuisineSelections: state.selectedCuisines.join(", "), // Convert array to comma-separated string
        restaurantDeliveryTimeWindowIs247: state.is24x7Open,
        restaurantTakeawayTimeWindowIs247: state.is24x7DiningOpen,
        restaurantDeliveryTimeWindow: Object.keys(state.days)
          .filter((day) => state.days[day].enabled)
          .map((day) => ({
            dayOfTheWeek: day,
            windowStart: `${state.days[day].slots[0].open}:00:00`,
            windowEnd: `${state.days[day].slots[0].close}:00:00`,
          })),
        restaurantTakeawayTimeWindow: Object.keys(state.diningDays)
          .filter((day) => state.diningDays[day].enabled)
          .map((day) => ({
            dayOfTheWeek: day,
            windowStart: `${state.diningDays[day].slots[0].open}:00:00`,
            windowEnd: `${state.diningDays[day].slots[0].close}:00:00`,
          })),
        devicesOpted: state.selectedDevices,
      };
      try {
        const response = await updateMenuOperation(menuOperationData, state.restaurantId);
        localStorage.setItem("menuOperationalComplete", "true");
        navigate("/restaurant-documents"); // Assuming this is the route for the next page
      } catch (error) {
        handleError(error);
      }

    }
    else {
      alert("Please fill all the required fields correctly.");
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("menuOperationData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setState((prevState) => ({
        ...prevState,
        selectedDevices: parsedData.selectedDevices || [], // Fallback to empty array
      }));
    }
  }, []);


  useEffect(() => {
    const fetchMenuOperationData = async () => {
      try {
        const response = await readMenuOperation(state.restaurantId);

        // Parsing the response data and mapping it to the state
        const {
          cuisineSelections = "", // If no cuisines, default to an empty string
          restaurantDeliveryTimeWindow = [], // Default to an empty array if no delivery window
          restaurantTakeawayTimeWindow = [], // Default to an empty array if no takeaway window
          devicesOpted = [] // Default to an empty array if no devices
        } = response;

        // Map the fetched data to the state
        setState((prevState) => ({
          ...prevState,
          selectedCuisines: cuisineSelections ? cuisineSelections.split(", ") : [], // Split the cuisine string into an array
          is24x7Open: restaurantDeliveryTimeWindow.length === 0 ? false : checkIf247(restaurantDeliveryTimeWindow), // Check if 24/7 is open for delivery
          days: mapTimeWindowToDays(restaurantDeliveryTimeWindow), // Map the time window to the days state
          is24x7DiningOpen: restaurantTakeawayTimeWindow.length === 0 ? false : checkIf247(restaurantTakeawayTimeWindow), // Check if 24/7 is open for dining
          diningDays: mapTimeWindowToDays(restaurantTakeawayTimeWindow, true), // Map the time window to the dining days state
          selectedDevices: devicesOpted.length > 0 ? devicesOpted : null, // Set the selected device
        }));
      } catch (error) {
        console.error("Error fetching menu operation data:", error);
      }
    };

    fetchMenuOperationData();
  }, []);

  // Helper functions to map response data to state

  const checkIf247 = (timeWindow) => {
    // Check if the time window is 24/7 (open: 0, close: 24 for all days)
    return timeWindow.every((window) => window.windowStart === "00:00:00" && window.windowEnd === "24:00:00");
  };

  const mapTimeWindowToDays = (timeWindow) => {
    // Map the response time window to the state days or diningDays format
    const daysMapping = {
      Monday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Tuesday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Wednesday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Thursday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Friday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Saturday: { slots: [{ open: 9, close: 21 }], enabled: false },
      Sunday: { slots: [{ open: 9, close: 21 }], enabled: false },
    };

    timeWindow.forEach((window) => {
      const day = window.dayOfTheWeek;
      if (daysMapping[day]) {
        daysMapping[day].enabled = true;
        daysMapping[day].slots = [
          { open: parseInt(window.windowStart.split(":")[0]), close: parseInt(window.windowEnd.split(":")[0]) },
        ];
      }
    });

    return daysMapping;
  };

  return (
    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f7f7f7">


      <div className="registration bg-gray-100 min-h-screen flex flex-col">
        <main className="pt-20 pb-20 flex w-full">
          <div className="content flex-1 overflow-y-auto p-6 bg-gray-100">
            {loading ? (<Skeleton className="mt-3 mb-3" height={40}></Skeleton>) : (
              <h1 className="text-3xl font-bold mb-4">
                Menu and other operation details
              </h1>
            )}

            {/* Add Restaurant image section */}

            <div className="bg-white shadow-lg rounded-lg p-5 w-full ">
              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (
                <>
                  <h2 className="text-xl font-bold">Add restaurant images</h2>
                  <p className="text-sm text-gray-400 mb-4">
                    Upload at least one entrance image of your restaurant along with
                    interior images, for your {APP_NAME} page</p>
                  <div className="mb-2">
                    <input type="file" multiple accept="image/jpeg, image/png, image/jpg" onChange={handleRestaurantImageChange} className="hidden" id="restaurant-image-upload" />
                    <label htmlFor="restaurant-image-upload" className="cursor-pointer">
                      <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                        <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />
                        <span className="add-images-btn font-semibold">
                          Add more images
                        </span>
                        <p className="text-sm">jpeg, png or jpg formats up to 5MB</p>
                      </div>
                    </label>
                  </div>
                  <div className="flex flex-wrap justify-center">
                    {state.restaurantImages.map((image, index) => (
                      <div key={index} className="relative m-2">
                        <img
                          src={image || example}
                          alt={`restaurant-${index}`}
                          className="w-24 h-24 object-cover rounded"
                        />
                        <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                          {index + 1}
                        </div>
                        <button
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                          onClick={() => handleImageDelete("restaurant", image)}>
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isRestaurentModalOpen: true,
                        }))
                      }
                      className="text-green-500 underline"
                    >
                      Guidelines to upload restaurant images
                    </button>
                  </div>
                </>
              )}
            </div>

            {/* Rendering the Restaurant image component  */}
            <RestaurantImages isOpen={state.isRestaurentModalOpen} onClose={() => setState((prevState) => ({ ...prevState, isRestaurentModalOpen: false }))} />

            {/* Food images */}
            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
              {loading ? (<Skeleton className="mt-3 mb-3 " height={60}></Skeleton>) : (

                <>
                  <h2 className="text-xl font-bold">Add food images (optional)</h2>
                  <p className="text-sm text-gray-400 mb-4">
                    These images will be shown on your restaurant's {APP_NAME} dining
                    page.
                  </p>
                  <div className="mb-2">
                    <input type="file" multiple accept="image/jpeg, image/png, image/jpg" onChange={handleFoodImageChange} className="hidden" id="food-image-upload" />
                    <label htmlFor="food-image-upload" className="cursor-pointer">
                      <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                        <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />
                        <span className="add-images-btn font-semibold">
                          Add more images
                        </span>
                        <p className="text-sm">jpeg, png or jpg formats up to 5MB</p>
                      </div>
                    </label>
                  </div>
                  <div className="flex flex-wrap justify-center">
                    {state.foodImages.map((image, index) => (
                      <div key={index} className="relative m-2">
                        <img src={image || example} alt={`food-${index}`} className="w-24 h-24 object-cover rounded" />
                        <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                          {index + 1}
                        </div>
                        <button
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                          onClick={() => handleImageDelete("food", image)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isFoodModalOpen: true,
                        }))
                      }
                      className="text-green-500 underline"
                    >
                      Guidelines to upload food images
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* Rendering the Food Image component  */}
            <FoodImages isOpen={state.isFoodModalOpen} onClose={() => setState((prevState) => ({ ...prevState, isFoodModalOpen: false }))} />
            {/* Delivery Menu images */}

            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (

                <>
                  <h2 className="text-xl font-bold">Add delivery menu images</h2>
                  <p className="text-sm text-gray-400 mb-4">
                    These will be used to create your in-app menu for online ordering.
                  </p>
                  <div className="mb-2">
                    <input type="file" multiple accept="image/jpeg, image/png, image/jpg" onChange={handleDeliveryMenuImageChange} className="hidden" id="delivery-menu-image-upload" />
                    <label htmlFor="delivery-menu-image-upload" className="cursor-pointer">
                      <div className="upload-images-border border-dashed border-2 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                        <FontAwesomeIcon icon={faPlus} className="camera-icon w-8 h-8 mb-1" />
                        <span className="add-images-btn font-semibold">
                          Add delivery menu images
                        </span>
                        <p className="text-sm">jpeg, png or jpg formats up to 5MB</p>
                      </div>
                    </label>
                  </div>
                  <div className="flex flex-wrap justify-center">
                    {state.deliveryMenuImages.map((image, index) => (
                      <div key={index} className="relative m-2">
                        <img src={image || example} alt={`deliveryMenu-${index}`} className="w-24 h-24 object-cover rounded" />
                        <div className="absolute top-0 left-0 bg-gray-700 text-white text-xs w-6 h-6 flex items-center justify-center rounded-full">
                          {index + 1}
                        </div>
                        <button
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                          onClick={() => handleImageDelete("deliveryMenu", image)}
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isDelieveryMenuOpen: true,
                        }))
                      }
                      className="text-green-500 underline"
                    >
                      Guidelines to upload delivery menu images
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* Rendering the Delivery Menu Component */}
            <DeliveryMenu isOpen={state.isDelieveryMenuOpen} onClose={() => setState((prevState) => ({ ...prevState, isDelieveryMenuOpen: false }))} />
            {/* Profile Image */}
            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">

              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (


                <>
                  <h1 className="text-xl font-bold">Add restaurant profile image</h1>
                  <p className="text-sm text-gray-400 mb-2">
                    This will be your restaurant's profile picture on {APP_NAME} - so
                    use your best food shot! </p>
                  <div className="bg-white shadow-lg rounded-lg p-5 w-full flex">
                    <div className="w-full flex flex-col items-center mb-2">
                      {state.profileImage ? (
                        <div className="relative w-full h-96">
                          <img src={state.profileImage || example} alt="restaurant-profile" className="w-full h-full object-cover rounded border border-gray-400" />
                          <button
                            className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center"
                            onClick={handleProfileImageDelete}>
                            &times;
                          </button>
                          <input type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleProfileImageUpload} className="hidden" id="profile-image-upload" />
                          <p className="text-sm font-semibold text-black mt-1 break-all">
                            {state.profileImage.name}
                          </p>
                        </div>
                      ) : (
                        <label htmlFor="profile-image-upload" className="border-dashed border-2 cursor-pointer border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center text-gray-600">
                          <FontAwesomeIcon icon={faUpload} className="camera-icon w-8 h-8 mb-1" />
                          <span className="add-images-btn font-semibold">
                            Add profile image
                          </span>
                          <p className="text-sm">
                            jpeg, png or jpg formats up to 5MB
                          </p>
                          <input type="file" accept="image/jpeg, image/png, image/jpg" onChange={handleProfileImageUpload} className="hidden" id="profile-image-upload" />
                        </label>
                      )}
                    </div>
                    {!state.profileImage && (
                      <div className="flex flex-col items-center justify-center  w-full">
                        <img src={example} alt="Example of profile picture" className="w-72 h-56 object-cover rounded" />
                      </div>
                    )}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isProfileImageOpen: true,
                        }))
                      }
                      className="text-green-500 underline"
                    >
                      Guidelines to upload restaurant profile images
                    </button>
                  </div>
                </>
              )}
            </div>
            {/* Navigate to Restaurent Image  component*/}
            <RestaurentProfileImages isOpen={state.isProfileImageOpen} onClose={() => setState((prevState) => ({ ...prevState, isProfileImageOpen: false }))} />

            {/* Cuisine Selection */}
            <div className="bg-white shadow-lg rounded-lg mt-4 p-5 w-full">
              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (
                <>
                  <h1 className="text-xl font-bold">Select up to 10 cuisines</h1>

                  <p className="text-sm text-gray-400 mb-2">
                    Your restaurant will appear in searches for these cuisines
                  </p>
                  <div className="relative mb-4">
                    <FontAwesomeIcon icon={faChevronDown} className="absolute left-3 top-3 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search for cuisines"
                      value={state.searchQuery}
                      onChange={(e) => setState((prevState) => ({ ...prevState, searchQuery: e.target.value }))}
                      className="w-full pl-10 p-2 border border-gray-300 outline-none rounded"
                    />
                  </div>
                  {/* Error Message Display */}
                  {state.errorMessage && (
                    <div className="text-red-600 font-bold text-center mt-3 mb-3">
                      {state.errorMessage}
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-2">
                    {state.cuisines
                      .filter((cuisine) => cuisine.toLowerCase().includes(state.searchQuery.toLowerCase()))
                      .slice(0, state.showAll ? undefined : 6)
                      .map((cuisine, index) => (
                        <button
                          key={index}
                          onClick={() => handleCuisineClick(cuisine)}
                          className={`p-2 cuisine-list border rounded text-center ${state.selectedCuisines.includes(cuisine)
                            ? "selected-cuisine "
                            : "not-selected-cuisine bg-white"
                            }`}
                        >
                          {cuisine}
                        </button>
                      ))}

                    {/* Show message when no cuisines match the search */}
                    {state.cuisines
                      .filter((cuisine) => cuisine.toLowerCase().includes(state.searchQuery.toLowerCase()))
                      .length === 0 && (
                        <div className="col-span-2 text-center text-red-600 font-semibold">
                          The searched cuisine is not listed.
                        </div>
                      )}

                  </div>

                  {!state.showAll && state.cuisines.length > 6 && (
                    <button onClick={() => setState((prevState) => ({ ...prevState, showAll: true }))} className="show-more-btn underline mt-4 block ml-auto">
                      Show more
                    </button>
                  )}

                  {state.cuisines.length <= 6 && (
                    <div className="text-center text-gray-500 mt-2">No more cuisines to show</div>
                  )}
                </>
              )}

            </div>

            {/* Delivery timing and days */}
            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (
                <>
                  <h1 className="text-xl font-bold mb-2">
                    Restaurant delivery hours
                  </h1>
                  <hr className="-mx-5 mb-2" />
                  <div className="flex flex-wrap gap-2 mb-4">
                    <div className="flex flex-wrap gap-2 mb-4">
                      {Object.keys(state.days).map((day) => (
                        <label key={day} className="flex items-center space-x-2 rounded-md border border-green-500 px-1 py-1">
                          <input
                            type="checkbox"
                            checked={state.days[day].enabled}
                            onChange={() => handleDayChange(day)}
                            className="h-4 w-4 border border-green-500 rounded-sm checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-0 relative"
                            id={day}
                            disabled={state.is24x7Open} // Disable individual day selection when 24/7 is active
                          />
                          <span
                            className={`cursor-pointer text-md ${state.days[day].enabled
                              ? "text-black"
                              : "bg-white text-gray-700 border-gray-300"
                              }`}
                            onClick={() => handleDayChange(day)}
                          >
                            {day}
                          </span>
                        </label>
                      ))}
                    </div>

                    {/* 24/7 Checkbox */}

                    <div className="mb-4">
                      <label className="flex items-center space-x-2  rounded-md border border-green-500 px-1 py-1">
                        <input
                          type="checkbox"
                          checked={state.is24x7Open}
                          onChange={handle24x7Change}
                          className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                        />
                        <span className="text-sm   text-gray-700 px-1 py-1 flex items-center space-x-2 rounded-md">
                          24/7 Open
                        </span>
                      </label>
                    </div>
                  </div>

                  {/* Delivery timing and days */}
                  <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                    <h1 className="text-xl font-bold mb-2">
                      Restaurant delivery hours
                    </h1>
                    <hr className="-mx-5 mb-2" />

                    {/* Display time slots for selected days */}
                    {Object.keys(state.days).map(
                      (day) =>
                        state.days[day].enabled && (
                          <div key={day}>
                            {state.days[day].slots.map((slot, index) => (
                              <div key={index} className="mb-2">
                                <div className="w-full pr-2">
                                  {index === 0 && (
                                    <label className="block text-lg text-black-500 mb-1">
                                      {day}
                                    </label>
                                  )}
                                  <div className="flex justify-between">
                                    <div className="w-full">
                                      <p className="text-grey text-sm">Open Time</p>
                                      <select
                                        value={slot.open}
                                        onChange={(e) =>
                                          handleTimeChange(day, index, "open", parseInt(e.target.value))
                                        }
                                        className="block w-full p-1 border border-gray-300 rounded-md"
                                        disabled={state.is24x7Open} // Disable time changes when 24/7 is active
                                      >
                                        {[...Array(24)].map((_, i) => (
                                          <option key={i} value={i}>
                                            {i < 12 ? `${i} AM` : `${i - 12} PM`}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div className="w-full mx-2">
                                      <p className="text-grey text-sm">Close Time</p>
                                      <select
                                        value={slot.close}
                                        onChange={(e) =>
                                          handleTimeChange(day, index, "close", parseInt(e.target.value))
                                        }
                                        className="block w-full p-1 border border-gray-300 rounded-md"
                                        disabled={state.is24x7Open} // Disable time changes when 24/7 is active
                                      >
                                        {[...Array(24)].map((_, i) => (
                                          <option key={i} value={i}>
                                            {i < 12 ? `${i} AM` : `${i - 12} PM`}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <button
                              onClick={() => addTimeSlot(day)}
                              className="p-1 mb-2 text-white rounded text-center rounded:sm mt-2 bg-green-400 hover:bg-green-500"
                              disabled={state.is24x7Open} // Disable slot addition when 24/7 is active
                            >
                              Add Additional Slots
                            </button>
                          </div>
                        )
                    )}
                  </div>
                </>
              )}
            </div>

            {/* Dining time */}
            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
              {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (
                <>
                  <h1 className="text-xl font-bold mb-2">Restaurant dining hours</h1>
                  <hr className="-mx-5 mb-2" />
                  <div className="mb-4">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={state.diningHoursSameAsDelivery}
                        onChange={handleDiningHoursCheckboxChange}
                        className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                      />
                      <span className="text-sm text-gray-700">
                        My dining hours are same as delivery hours
                      </span>
                    </label>
                  </div>
                  {!state.diningHoursSameAsDelivery && (
                    <>
                      <div className="flex flex-wrap gap-2 mb-2">
                        <div className="flex flex-wrap gap-2 mb-4">
                          {Object.keys(state.diningDays).map((day) => (
                            <label
                              key={day}
                              className="flex items-center space-x-2 rounded-md border border-green-500 px-1 py-1">
                              <input
                                type="checkbox"
                                checked={state.diningDays[day].enabled}
                                onChange={() => handleDiningDayChange(day)}
                                className="appearance-none h-4 w-4 border border-green-500 rounded-sm checked:bg-green-500 checked:border-transparent focus:outline-none focus:ring-0 relative"
                                id={day}
                              />
                              <span
                                className={`cursor-pointer text-sm ${state.diningDays[day].enabled
                                  ? "text-black"
                                  : "bg-white text-gray-700 border-gray-300"
                                  }`}
                              >
                                {day}
                              </span>
                            </label>
                          ))}
                        </div>
                        <div className="flex flex-wrap gap-2 mb-4">
                          <label className="flex items-center space-x-2 rounded-md border border-green-500 px-1 py-1">
                            <input
                              type="checkbox"
                              checked={state.is24x7DiningOpen}
                              onChange={handle24x7DiningChange}
                              className="h-4 w-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                            />
                            <span className="text-sm text-gray-700 px-1 py-1 flex items-center space-x-2 rounded-md">
                              24/7 Open
                            </span>
                          </label>
                        </div>
                      </div>
                      {Object.keys(state.diningDays).map(
                        (day) =>
                          state.diningDays[day].enabled &&
                          state.diningDays[day].slots.map((slot, index) => (
                            <div key={index} className="flex justify-between mb-4">
                              <div className="w-full pr-2">
                                <label className="block text-sm text-gray-500 mb-1">
                                  {day} Open Time
                                </label>
                                <select
                                  value={slot.open}
                                  onChange={(e) =>
                                    handleDiningTimeChange(
                                      day,
                                      index,
                                      "open",
                                      parseInt(e.target.value)
                                    )
                                  }
                                  className="block w-full p-1 border border-gray-300 rounded-md"
                                  disabled={state.is24x7DiningOpen}
                                >
                                  {[...Array(24)].map((_, i) => (
                                    <option key={i} value={i}>
                                      {i < 12 ? `${i} AM` : `${i - 12} PM`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="w-full pl-2">
                                <label className="block text-sm text-gray-500 mb-1">
                                  {day} Close Time
                                </label>
                                <select
                                  value={slot.close}
                                  onChange={(e) =>
                                    handleDiningTimeChange(
                                      day,
                                      index,
                                      "close",
                                      parseInt(e.target.value)
                                    )
                                  }
                                  className="block w-full p-1 border border-gray-300 rounded-md"
                                  disabled={state.is24x7DiningOpen}
                                >
                                  {[...Array(24)].map((_, i) => (
                                    <option key={i} value={i}>
                                      {i < 12 ? `${i} AM` : `${i - 12} PM`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ))
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            {/* Select the device for order acceptance */}
            <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
              {/* <h2 className="text-xl font-bold mb-2">
              Select the device type you will use for order acceptance
            </h2> */}
              <div className="space-y-6">
                {/* Genie partner mobile app [Android Only] */}


                {/* <label className={`flex items-center w-full px-4 py-4 border-2 border-gray-300 rounded-lg cursor-pointer
                 ${state.selectedDevices.includes("GenieApp")
                  ? "bg-green-600 text-white" : "bg-white text-gray-800"}`}>
                <input
                  type="checkbox"
                  checked={state.selectedDevices ? state.selectedDevices.includes("GenieApp") : ""}
                  onChange={() => handleDeviceSelect("GenieApp")}
                  className="mr-3 appearance-none"
                />
                <img src="https://via.placeholder.com/40" alt="Genie app" className="w-10 h-10 mr-3" />
                <span className="text-left text-lg flex-1">
                  {APP_NAME} partner mobile app
                  {APP_NAME} partner mobile app
                </span>
              </label> */}
                {/* Genie web dashboard */}
                {/* <label className={`flex items-center w-full px-4 py-4 border-2 border-gray-300 rounded-lg cursor-pointer
                 ${state.selectedDevices.includes("WebDashboard")
                  ? "bg-green-600 text-white" : "bg-white text-gray-800"}`}>
                <input
                  type="checkbox"
                  checked={state.selectedDevices.includes("WebDashboard")}
                  onChange={() => handleDeviceSelect("WebDashboard")}
                  className={`mr-3 appearance-none`}
                />
                <img src="https://via.placeholder.com/40" alt="Genie web dashboard" className="w-10 h-10 mr-3" />
                <span className="text-left text-lg flex-1">
                  {APP_NAME} web dashboard
                </span>
              </label> */}


                <div className="bg-white shadow-lg rounded-lg p-5 mt-4 w-full">
                  {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (
                    <>
                      <h2 className="text-xl font-bold mb-2">
                        Select the device type you will use for order acceptance
                      </h2>
                      <div className="space-y-6">
                        {/* Genie partner mobile app [Android Only] */}
                        <label className={`flex items-center w-full px-4 py-4 border-2 border-gray-300 rounded-lg cursor-pointer ${Array.isArray(state.selectedDevices) && state.selectedDevices.includes("GenieApp")
                          ? "bg-green-600 text-white"
                          : "bg-white text-gray-800"
                          }`}>
                          <input
                            type="checkbox"
                            checked={Array.isArray(state.selectedDevices) && state.selectedDevices.includes("GenieApp")}
                            onChange={() => handleDeviceSelect("GenieApp")}
                            className="mr-3 appearance-none"
                          />
                          <img src="https://via.placeholder.com/40" alt="Genie app" className="w-10 h-10 mr-3" />
                          <span className="text-left text-lg flex-1">
                            {APP_NAME} partner mobile app
                          </span>
                        </label>
                        {/* Genie web dashboard */}
                        <label className={`flex items-center w-full px-4 py-4 border-2 border-gray-300 rounded-lg cursor-pointer ${Array.isArray(state.selectedDevices) && state.selectedDevices.includes("WebDashboard")
                          ? "bg-green-600 text-white"
                          : "bg-white text-gray-800"
                          }`}>
                          <input
                            type="checkbox"
                            checked={Array.isArray(state.selectedDevices) && state.selectedDevices.includes("WebDashboard")}
                            onChange={() => handleDeviceSelect("WebDashboard")}
                            className="mr-3 appearance-none"
                          />
                          <img src="https://via.placeholder.com/40" alt="Genie web dashboard" className="w-10 h-10 mr-3" />
                          <span className="text-left text-lg flex-1">
                            {APP_NAME} web dashboard
                          </span>
                        </label>
                      </div>
                    </>
                  )}
                </div>





              </div>
            </div>






          </div>
        </main>

        <footer className="fixed bottom-0 w-2/3">
          {/* Section for save as draft  */}
          <div className="flex justify-center md:justify-end sm:justify-end p-4 space-x-4">
            {loading ? (<Skeleton className="mt-3 mb-3" height={60}></Skeleton>) : (

              <>
                {/* Save As Draft Button */}
                <div className="flex flex-col items-center md:items-start">
                  <button
                    className={"bg-slate-500 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center whitespace-nowrap cursor-pointer opacity-100"

                    }
                    onClick={handleSaveDraft}
                  // disabled={!state.isFormValid} // Button is disabled if the form is invalid
                  >
                    Save As Draft
                  </button>

                  {/* Display Save Confirmation Message */}
                  {saveMessage && (
                    <p className="text-green-600 mt-2 text-center md:text-left">
                      {saveMessage}
                    </p>
                  )}
                </div>
                {/* Next Button  */}
                <button className={`bg-green-600 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center
          ${state.isFormValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                  }`}
                  onClick={handleNextClick}
                  disabled={!state.isFormValid}
                >
                  Next
                </button>
              </>
            )}
          </div>
        </footer>
        {/* Modal for Logout Confirmation */}
        {state.showLogoutConfirm && (
          <LogoutModel
            onClickCancel={() => setState((prevState) => ({ ...prevState, showLogoutConfirm: false }))}
            onClickLogout={() => {
              setState((prevState) => ({ ...prevState, showLogoutConfirm: false }));
              navigate("/"); // Redirect to login page
            }}
          />
        )}
        {/* Modal for Logout Options */}
        {state.showLogoutOptions && (
          <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${state.showLogoutModal}`}>
            <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-md">
              <h2 className="text-xl text-center font-semibold mb-3">
                Select Logout Option
              </h2>
              <div className="flex justify-between mt-10">
                <button className="bg-green-500 text-white text-sm text-center p-3 rounded-lg mr-4" onClick={() => handleLogoutClick("Session")}>
                  Logout from This Device
                </button>
                <button className="bg-red-600 text-white text-sm text-center p-3 rounded-lg" onClick={() => handleLogoutClick("Account")}>
                  Logout from All Devices
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal for Documents Required */}
        {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}

        {/* Modal for Refer */}
        {state.showReferModal && (
          <ReferrerModal
            toggleReferModal={toggleReferModal}
            onClickSuccess={() => {
              toggleReferModal();
            }}
          />
        )}
      </div>

    </SkeletonTheme>
  );
};
export default MenuOperational;








