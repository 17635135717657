import { React, useEffect, useState } from "react";
import { faFileAlt, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RequiredRegistrationCard = ({ onClickCard }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading period (e.g., for fetching data)
    const timeout = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (

    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f7f7f7">
      {loading ? (
        <Skeleton height={60} width={320} />
      ) : (
        <div
          className="docNotification flex justify-between items-center border p-3 rounded-lg cursor-pointer"
          onClick={onClickCard}
        >
          <div className="flex gap-2">
            {loading ? (
              <Skeleton height={40} width={40} />
            ) : (
              <FontAwesomeIcon icon={faFileAlt} className="icons text-xl" />
            )}

            {loading ? (
              <Skeleton height={40} width={200} /> // Adjust width and height as needed
            ) : (
              <span className="text-black font-semibold me-8">
                Documents required for registration
              </span>
            )}
          </div>

          <div>
            {/* <FontAwesomeIcon icon={faPlay} className="icons text-base" /> */}
          </div>
        </div>
      )}
    </SkeletonTheme>
  );
};

export default RequiredRegistrationCard;
