// import React, { useState } from 'react';
// import './Home.css';
// import Logo from '../images/logo.webp'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import Login from './Login';
// import { Link } from 'react-router-dom';
// import { APP_NAME } from "../constants";

// const Home = () => {
//   const [isLoginModalOpen, setLoginModalOpen] = useState(false);

//   return (
//     <div className="home-container h-screen flex flex-col justify-center items-center">
//       <div className="home flex flex-col w-full">
//         {/* Header section */}
//         <div className="header flex justify-between items-center text-white px-6 py-4 sm:px-8 lg:px-20">
//           <img src={Logo} alt="Logo" className="logo w-32 sm:w-32 lg:w-40"/>
//         </div>
//         {/* Main content section */}
//         <div className="flex flex-col justify-center items-center flex-grow text-center px-4 lg:px-0">
//           <div className="content text-center">
//             <h1 className="text-white text-3xl sm:text-3xl lg:text-4xl font-bold mb-1">
//               Partner with {APP_NAME}
//             </h1>
//             <p className="text-white text-xl sm:text-2xl  lg:text-3xl font-bold mb-4 whitespace-nowrap">
//               at 0% commission for the 1st month!
//             </p>
//             <p className="text-white text-sm sm:text-base lg:text-lg text-base mb-2">
//               And get ads worth INR 1500. Valid for new restaurant partners in
//               select.
//             </p>
//             <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-3 sm:space-y-0 sm:space-x-4">
//               <button
//                 className="register-btn cursor-pointer text-white text-sm sm:text-base py-2 px-6 sm:px-12 rounded w-full sm:w-auto bg-green-600 whitespace-nowrap"
//                 onClick={() => setLoginModalOpen(true)}
//               >
//                 Register your restaurant
//               </button>
//               <Link
//                 to="https://merchant.thegenie.in/login"
//                 className="bg-white hover:bg-gray-200 cursor-pointer text-black text-sm sm:text-base  py-2 px-6 sm:px-10 rounded whitespace-nowrap"
//               >
//                 Login to view your existing restaurants
//               </Link>
//             </div>
//             <p className="text-white text-sm mt-2">
//               Need help? Contact:{" "}
//               <a href="tel:+91 9080777754" className="phoneNum">
//                 +91 90-80-77-77-54
//               </a>
//             </p>
//           </div>
//         </div>
//         {/* Additional Content Section */}
//         <div className=" mb-8 lg:mb-12 bg-white text-black p-4 sm:p-6 rounded shadow-lg max-w-lg sm:max-w-2xl mx-auto">
//           <h2 className="text-lg sm:text-xl font-bold mb-1 text-center">
//             Get started with online ordering
//           </h2>
//           <p className="text-sm sm:text-base text-gray-500 text-center mb-4">
//             Please keep the documents ready for smooth signup
//           </p>
//           <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//             <ul className="space-y-3 sm:space-y-4">
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 FSSAI license copy
//               </li>
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 Regular GSTIN
//               </li>
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 Your restaurant menu
//               </li>
//             </ul>
//             <ul className="space-y-3 sm:space-y-4">
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 PAN card copy
//               </li>
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 Bank account details
//               </li>
//               <li className="flex items-center">
//                 <FontAwesomeIcon
//                   icon={faCheckCircle}
//                   className="doc-icon mr-2"
//                 />
//                 Dish images for top 5 items
//               </li>
//             </ul>
//           </div>
//         </div>
//         {/* Login Modal */}
//         <Login
//           isOpen={isLoginModalOpen}
//           onClose={() => setLoginModalOpen(false)}
//         />
//       </div>
//     </div>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import "./Home.css";
import Logo from "../images/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Login from "./Login";
import { Link } from "react-router-dom";
import { APP_NAME } from "../constants";
import bgImage from "../images/bg.webp";

const Home = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading period for 2 seconds
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={`home-container h-screen flex flex-col justify-center items-center ${loading ? "" : "bg-cover bg-center no-repeat center center fixed w-full"
        }`}
      style={{
        backgroundImage: loading
          ? "none"
          : `url(${bgImage}`,
      }}
    >
      <div className=" flex flex-col w-full">
        {/* Header Section */}
        <div className="header flex justify-between items-center text-white px-6 py-4 sm:px-8 lg:px-20">
          <img
            src={Logo}
            alt="Logo"
            className="logo w-32 sm:w-32 lg:w-40"
          />
        </div>

          {/* Main Content Section */}
          <div className="flex flex-col justify-center items-center flex-grow text-center px-4 lg:px-0">
            <div className="content text-center">
             
                <>
                  <h1 className="text-white text-3xl sm:text-3xl lg:text-4xl font-bold mb-1">
                    Partner with {APP_NAME}
                  </h1>
                  <p className="text-white text-xl sm:text-2xl lg:text-3xl font-bold mb-4 whitespace-nowrap">
                    at 0% commission for the 1st month!
                  </p>
                  <p className="text-white sm:text-base lg:text-lg text-base mb-2">
                    And get ads worth INR 1500. Valid for new restaurant
                    partners in select.
                  </p>
                </>
              <div className="flex flex-col sm:flex-row justify-center mt-4 space-y-3 sm:space-y-0 sm:space-x-4">
               
                  <button
                    className="register-btn cursor-pointer text-white text-sm sm:text-base py-2 px-6 sm:px-12 rounded w-full sm:w-auto bg-green-600 whitespace-nowrap"
                    onClick={() => setLoginModalOpen(true)}
                  >
                    Register your restaurant
                  </button>
                
                  <Link
                    to="https://merchant.thegenie.in/login"
                    className="bg-white hover:bg-gray-200 cursor-pointer text-black text-sm sm:text-base py-2 px-6 sm:px-10 rounded whitespace-nowrap"
                  >
                    Login to view your existing restaurants
                  </Link>
              </div>
            
                <p className="text-white text-sm mt-2">
                  Need help? Contact:{" "}
                  <a href="tel:+91 9080777754" className="phoneNum">
                    +91 90-80-77-77-54
                  </a>
                </p>
            </div>
          </div>

        {/* Additional Content Section */}
        <div className="mb-8 lg:mb-12 bg-white text-black p-4 sm:p-6 rounded shadow-lg max-w-lg sm:max-w-2xl mx-auto">
        
            <>
              <h2 className="text-lg sm:text-xl font-bold mb-1 text-center">
                Get started with online ordering
              </h2>
              <p className="text-sm sm:text-base text-gray-500 text-center mb-4">
                Please keep the documents ready for smooth signup
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <ul className="space-y-3 sm:space-y-4">
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    FSSAI license copy
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    Regular GSTIN
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    Your restaurant menu
                  </li>
                </ul>
                <ul className="space-y-3 sm:space-y-4">
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    PAN card copy
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    Bank account details
                  </li>
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="doc-icon mr-2"
                    />
                    Dish images for top 5 items
                  </li>
                </ul>
              </div>
            </>
        
        </div>

        {/* Login Modal */}
        <Login
          isOpen={isLoginModalOpen}
          onClose={() => setLoginModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default Home;


