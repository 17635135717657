import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FaCalendarAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadGuidelinesModal from "../components/UploadGuidelinesModal";
import UploadGstGuidelines from "./UploadGstGuidelines";
import { logoutService } from "../services/LogoutService";
import UploadFssaiGuidelines from "./UploadFssaiGuidelines";
import {
  fetchPanUrl,
  deletePanUrl,
  fetchGstUrl,
  deleteGstUrl,
  fetchFssaiUrl,
  deleteFssaiUrl,
  confirmGstUpload,
  confirmFssaiUpload,
  uploadPanImage,
  uploadGstImage,
  uploadFssaiImage,
  getVerificationStatus,
  updateRestaurantDocuments,
  confirmPanUpload,
  readRestaurantDocuments,
} from "../services/Api";
import "./Registration.css";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { verifyPAN, verifyGST, verifyFSSAI, verifyBANK } from "../services/Api"; // Import API functions
import { format } from "date-fns";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RestaurantDocuments = () => {
  const [showModal, setShowModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const [panNumber, setPanNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [panVerified, setPanVerified] = useState(false);
  const [panInvalid, setPanInvalid] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [isGstRegistered, setIsGstRegistered] = useState(null);
  const [gstInvalid, setGstInvalid] = useState(false);
  const [gstVerified, setGstVerified] = useState(false);
  const [fssaiNumber, setFssaiNumber] = useState("");
  const [fssaiInvalid, setFssaiInvalid] = useState(false);
  const [fssaiVerified, setFssaiVerified] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [uploadedPanImage, setUploadedPanImage] = useState(null);
  const [uploadedGstImage, setUploadedGstImage] = useState(null);
  const [uploadedFssaiImage, setUploadedFssaiImage] = useState(null);
  const [panVerificationStatus, setPanVerificationStatus] = useState(null);
  const [gstVerificationStatus, setGstVerificationStatus] = useState(null);
  const [fssaiVerificationStatus, setFssaiVerificationStatus] = useState(null);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(null);
  const [isPanModalOpen, setIsPanModalOpen] = useState(false);
  const [isGSTModalOpen, setIsGSTModalOpen] = useState(false);
  const [isFssaiModalOpen, setIsFssaiModalOpen] = useState(false);
  const datepickerRef = useRef(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [reEnterAccountNumber, setReEnterAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountType, setAccountType] = useState("");
  const [showAccountTypeError, setShowAccountTypeError] = useState(false); // New state for showing the account type error
  const [bankVerified, setBankVerified] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const restaurantId = localStorage.getItem("restaurant_Id");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPanVerifying, setIsPanVerifying] = useState(false);
  const [isGstVerifying, setIsGstVerifying] = useState(false);
  const [isFssaiVerifying, setIsFssaiVerifying] = useState(false);
  const [isBankVerifying, setIsBankVerifying] = useState(false);



  //shimer loading animation
  useEffect(() => {
    // Simulate a loading period (e.g., for fetching data)
    const timeout = setTimeout(() => {
      setLoading(false); // Stop loading after 2 seconds
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  //bankverification status
  useEffect(() => {
    const savedBankVerificationStatus = localStorage.getItem("bankVerificationStatus");
    if (savedBankVerificationStatus) {
      setBankVerificationStatus(savedBankVerificationStatus);
    }
    setLoading(false); // Simulate loading completion
  }, []);

  //bankverification status 
  useEffect(() => {
    localStorage.setItem("bankVerificationStatus", bankVerificationStatus);
  }, [bankVerificationStatus]);


  useEffect(() => {
    if (gstNumber || uploadedGstImage) {
      setIsGstRegistered("yes"); // If either gstNumber or uploadedGstImage is present, set to Yes
    } else {
      setIsGstRegistered("no"); // If both are absent, set to No
    }
  }, [gstNumber, uploadedGstImage]);

  useEffect(() => {
    validateForm();
  }, [
    panNumber,
    fullName,
    address,
    isGstRegistered,
    fssaiNumber,
    expiryDate,
    accountNumber,
    reEnterAccountNumber,
    ifscCode,
    accountType,
    uploadedPanImage,
    uploadedGstImage,
    uploadedFssaiImage,
  ]);

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        fetchDocuments(),
        fetchDocumentUrls(),
        fetchVerificationStatus(),
      ]);
    };
    fetchAllData();
  }, [restaurantId]);

  const fetchDocuments = async () => {
    try {
      const data = await readRestaurantDocuments(restaurantId);
      // Update state with fetched data
      setPanNumber(data.panNumber);
      setFullName(data.panFullName);
      setGstNumber(data.gstNumber);
      setIsGstRegistered(data.gstNumber ? "yes" : "no");
      setAddress(data.gstAddress);
      setFssaiNumber(data.fssaiNumber);
      setExpiryDate(data.fssaiValidTo ? data.fssaiValidTo : null);
      setAccountNumber(data.bankAccountNumber);
      setReEnterAccountNumber(data.bankAccountNumber);
      setIfscCode(data.bankIfscCode);
      setAccountType(data.accountType);
    } catch (error) {
      console.error("Error fetching restaurant documents:", error);
    }
  };

  const fetchDocumentUrls = async () => {
    try {
      const panData = await fetchPanUrl(restaurantId);
      const gstData = await fetchGstUrl(restaurantId);
      const fssaiData = await fetchFssaiUrl(restaurantId);

      if (panData) {
        setUploadedPanImage(panData.panUrl || null);
      }

      if (gstData) {
        setUploadedGstImage(gstData.gstUrl || null);
        setIsGstRegistered("yes");
      } else {
        setIsGstRegistered("no");
      }

      if (fssaiData) {
        setUploadedFssaiImage(fssaiData.fssaiUrl || null);
      }
    } catch (error) {
      console.error("Error fetching document URLs:", error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);
  const toggleReferModal = () => setShowReferModal(!showReferModal);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const closeVerificationModal = () => setVerificationModal(false);
  const [verificationModal, setVerificationModal] = useState(false); // State for verification modal
  const [verificationMessage, setVerificationMessage] = useState(""); // State for verification message

  const fetchVerificationStatus = async () => {
    try {
      // Replace with actual restaurantId
      const response = await getVerificationStatus(restaurantId);
      let verifiedFields = [];
      // Check and handle verification status
      if (response.isPanVerified) {
        setPanVerified(true);
        verifiedFields.push("PAN details are verified.");
      }

      if (response.isGstVerified) {
        setIsGstRegistered("yes");
        setGstVerified(true);
        verifiedFields.push("GST details are verified.");
      }

      if (response.isFssaiVerified) {
        setFssaiVerified(true);
        verifiedFields.push("FSSAI details are verified.");
      }

      if (response.isBankVerified) {
        // setAccountNumber("");
        // setReEnterAccountNumber("");
        // setIfscCode("");
        // setAccountType("");
        setBankVerified(true);
        verifiedFields.push("Bank details are verified.");
      }

      //  const savedBankVerificationStatus = localStorage.getItem("bankVerificationStatus");
      // if (savedBankVerificationStatus === "verified") {
      //   setBankVerified(true);
      //   verifiedFields.push("Bank Details are Verified")
      // }



      // Show verification message modal if any verification is true
      if (verifiedFields?.length > 0) {
        setVerificationMessage(verifiedFields.join(" "));
        setVerificationModal(true);
      }
    } catch (error) {
      console.error("Error fetching verification status:", error);
    }
  };

  const confirmLogout = () => {
    setShowLogoutModal(false);
    logoutService("Session");
  };

  // PAN Verification
  const handlePanVerify = async () => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    setIsPanVerifying(true); // Set PAN verifying state
    if (panRegex.test(panNumber)) {
      try {
        await verifyPAN(panNumber, fullName, restaurantId);
        setPanVerified(true);
        setPanInvalid(false);
        setPanVerificationStatus("verified");
      } catch (error) {
        console.error("PAN verification failed:", error);
        setPanInvalid(true);
        setPanVerificationStatus("failed");
      }

      finally {
        setIsPanVerifying(false); // Reset PAN verifying state
      }

    } else {
      setPanVerified(false);
      setPanInvalid(true);
    }

  };

  // GST Verification
  const handleGstVerify = async () => {
    setIsGstVerifying(true); // Set GST verifying state
    if (
      isGstRegistered === "yes" &&
      gstNumber?.length === 15 &&
      uploadGstImage
      // && address
    ) {
      try {
        await verifyGST(gstNumber, address, restaurantId); // Pass the correct gstNumber and address
        setGstVerified(true);
        setGstInvalid(false);
        setGstVerificationStatus("verified");
      } catch (error) {
        console.error("GST verification failed:", error);
        setGstInvalid(true);
        setGstVerificationStatus("failed");
      }
      finally {
        setIsGstVerifying(false); // Reset GST verifying state
      }
    }
    else {
      setGstVerified(false);
      setGstInvalid(true);
    }
  };



  const convertTimeFormat = (expiryDate) => {
    // Parse the expiryDate into a Date object
    const date = new Date(expiryDate);
    // Format the date in "yyyy-MM-dd HH:mm:ss" format
    return format(date, "yyyy-MM-dd HH:mm:ss");
  };

  // FSSAI Verification
  const handleFssaiVerify = async () => {
    setIsFssaiVerifying(true); // Set GST verifying state
    if (fssaiNumber?.length === 14) {
      try {
        const validityDate = convertTimeFormat(expiryDate);

        await verifyFSSAI(fssaiNumber, validityDate, restaurantId); // Pass the correct fssaiNumber and expiryDate
        setFssaiVerificationStatus("verified");
        setFssaiVerified(true);
        setFssaiInvalid(false);
      } catch (error) {
        console.error("FSSAI verification failed:", error);
        setFssaiVerificationStatus("failed");
        setFssaiVerified(false);
        setFssaiInvalid(true);
      }
      finally {
        setIsFssaiVerifying(false); // Reset FSSAI verifying state
      }
    } else {
      setFssaiVerified(false);
      setFssaiInvalid(true);
    }

  };



  const handleImageUpload = async (
    e,
    uploadFunction,
    setImageState,
    confirmUploadFunction
  ) => {
    const file = e.target.files[0];
    let metadataId;

    try {
      if (!file) {
        throw new Error("No file selected");
      }

      const MAX_FILE_SIZE_MB = 10;
      const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

      // Check initial file size and block oversized files upfront
      if (file.size > MAX_FILE_SIZE_BYTES) {
        throw new Error("File size exceeds the 10MB limit.");
      }

      // Step 1: Resize and compress the image
      const processedFile = await compressImageUntilSize(
        file,
        MAX_FILE_SIZE_BYTES
      );

      // Step 2: Get presigned URL for upload
      const { presignedURL, metadataId: id } = await uploadFunction(
        file.type === "application/pdf" ? "pdf" : "png",
        restaurantId
      );
      metadataId = id;

      // Step 3: Upload the file to the server
      const response = await fetch(presignedURL, {
        method: "PUT",
        headers: {
          "Content-Type":
            file.type === "application/pdf" ? "application/pdf" : "image/png",
        },
        body: processedFile,
      });

      if (!response.ok) {
        throw new Error("File upload failed.");
      }

      // Step 4: Confirm the upload
      const confirmResponse = await confirmUploadFunction(
        metadataId,
        true,
        restaurantId
      );
      if (confirmResponse.status === "success") {
        setImageState(confirmResponse.publicUrl); // Update the image URL
      }
    } catch (error) {
      console.error("Error during image upload:", error);

      // Rollback metadata if upload fails
      if (metadataId) {
        await confirmUploadFunction(metadataId, false, restaurantId);
      }
    }
  };

  // Function to compress an image iteratively until it is less than MAX_FILE_SIZE_BYTES
  const compressImageUntilSize = async (
    file,
    maxSizeMB = 10,
    qualityStep = 0.1
  ) => {
    const MAX_SIZE_BYTES = maxSizeMB * 1024 * 1024;

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        try {
          let currentBlob = file;
          let quality = 1.0;

          while (currentBlob.size > MAX_SIZE_BYTES && quality > 0.1) {
            const img = new Image();
            img.src = reader.result;

            await new Promise((res) => {
              img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                // Maintain aspect ratio while resizing
                const scale = Math.sqrt(MAX_SIZE_BYTES / currentBlob.size);
                canvas.width = img.width * scale;
                canvas.height = img.height * scale;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                // Compress the image
                canvas.toBlob(
                  (blob) => {
                    if (!blob) {
                      reject(new Error("Compression failed"));
                    } else {
                      currentBlob = blob;
                      reader.readAsDataURL(currentBlob); // Reload the new compressed blob
                      res();
                    }
                  },
                  "image/jpeg",
                  quality
                );

                quality -= qualityStep; // Reduce quality in each iteration
              };
            });
          }

          if (currentBlob.size > MAX_SIZE_BYTES) {
            reject(
              new Error("Unable to compress image below the desired size")
            );
          } else {
            resolve(currentBlob);
          }
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject(new Error("File reading failed"));
      reader.readAsDataURL(file);
    });
  };

  // Function to convert images to PNG
  const convertToPng = async (file, MAX_FILE_SIZE_BYTES) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const img = new Image();
          img.onload = async () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const pngBlob = await new Promise((resolve) =>
              canvas.toBlob(resolve, "image/png")
            );

            if (pngBlob.size > MAX_FILE_SIZE_BYTES) {
              throw new Error(
                "Converted PNG exceeds the file size limit of 10MB."
              );
            }

            resolve(pngBlob);
          };
          img.onerror = () =>
            reject(new Error("Error loading image for conversion"));
          img.src = reader.result;
        } catch (err) {
          reject(err);
        }
      };

      reader.onerror = () =>
        reject(new Error("Error reading file for conversion"));
      reader.readAsDataURL(file);
    });
  };

  const handlePanImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadPanImage,
      setUploadedPanImage,
      confirmPanUpload
    );
    if (imageUrl) {
      // Ensure the URL is not null
      setUploadedPanImage(imageUrl);
    }
  };

  const handleGstImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadGstImage,
      setUploadedGstImage,
      confirmGstUpload
    );
    if (imageUrl) {
      // Ensure the URL is not null
      setUploadedGstImage(imageUrl);
    }
  };

  const handleFssaiImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadFssaiImage,
      setUploadedFssaiImage,
      confirmFssaiUpload
    );
    if (imageUrl) {
      // Ensure the URL is not null
      setUploadedFssaiImage(imageUrl);
    }
  };

  const handleDeleteImage = async (
    deleteFunction,
    imageUrlSetter,
    verificationStatusSetter,
    imageUrl
  ) => {
    try {
      await deleteFunction(imageUrl, restaurantId);
      imageUrlSetter(null);
      verificationStatusSetter(null);
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputValue?.length <= 18) {
      // Restrict to 18 characters
      setAccountNumber(inputValue);
      setBankVerificationStatus("false"); // Reset status on input change
    }
  };

  const handleReEnterAccountNumberChange = (e) => {
    setReEnterAccountNumber(e.target.value);

    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputValue?.length <= 18) {
      // Restrict to 18 characters
      setReEnterAccountNumber(inputValue);
      setBankVerificationStatus("false"); // Reset status on input change
    }
  };

  const handleIfscCodeChange = (e) => {
    setIfscCode(e.target.value);
    const inputValue = e.target.value.toUpperCase().replace(/\s+/g, ""); // Remove whitespace and uppercase
    if (/^[A-Z]{0,4}$/.test(inputValue.slice(0, 4))) {
      // Validate first 4 letters
      if (/^[0-9]{0,7}$/.test(inputValue.slice(4))) {
        // Validate last 7 numbers
        setIfscCode(inputValue.slice(0, 4) + inputValue.slice(4, 11)); // Restrict to 11 characters total
        setBankVerificationStatus("false"); // Reset status on input change
        setError(""); // Clear error on input change
      }
    }
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
    setShowAccountTypeError(false);
    setBankVerificationStatus("false"); // Reset status on input change
  };


  // BANK Verification
  const handleBankVerify = async () => {
    if (accountType === "") {
      setShowAccountTypeError(true); // Show error only when account type is not selected on verification attempt
      return; // Exit if account type is not selected
    }
    try {
      await verifyBANK(accountNumber, ifscCode, accountType, restaurantId);
      setBankVerificationStatus("verified"); // Update state
      localStorage.setItem("bankVerificationStatus", "verified");
      // Save to localStorage
    }

    catch (error) {
      console.error("Bank verification failed:", error);
      setBankVerificationStatus("error"); // Handle verification failure
    }
    finally {
      setIsBankVerifying(false); // Reset Bank verifying state
    }


    // Start verifying
    setBankVerificationStatus("verifying");
    setError("");

    // Simulate API call for verification
    setTimeout(() => {
      setBankVerificationStatus("verified");

      // Save verified details and status to localStorage
      const bankDetails = {
        accountNumber,
        reEnterAccountNumber,
        ifscCode,
        accountType,
      };
      localStorage.setItem("bankDetails", JSON.stringify(bankDetails));
      localStorage.setItem("bankverificationStatus", "verified");
    }, 3000); // Simulate 3 seconds verification delay
  };


  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const handleSaveAsDraft = () => {
    // Construct the object to save
    const restaurantDocumentsData = {
      panNumber: panNumber,
      panFullName: fullName,
      gstNumber: gstNumber,
      gstAddress: address,
      fssaiNumber: fssaiNumber,
      fssaiValidTo: expiryDate ? expiryDate.toISOString().split("T")[0] : null,
      bankAccountNumber: accountNumber,
      bankIfscCode: ifscCode,
      accountType: accountType,
    };

    // Save the data in localStorage
    localStorage.setItem(
      "restaurantDocumentsData",
      JSON.stringify(restaurantDocumentsData)
    );

    // Set a success message
    setSaveMessage("Details saved successfully!");

    // Clear the message after 3 seconds
    setTimeout(() => {
      setSaveMessage("");
    }, 3000);
  };

  const [saveMessage, setSaveMessage] = useState("");

  const validateForm = () => {
    const isAccountNumberValid =
      accountNumber?.length >= 9 && accountNumber?.length <= 18;
    const isReEnterAccountNumberValid = accountNumber === reEnterAccountNumber;
    const isIfscCodeValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode);
    const isAccountTypeValid = accountType !== "";
    const isPanDetailsValid =
      panNumber && fullName && address && uploadedPanImage;
    const isGstDetailsValid =
      isGstRegistered !== "yes" || (gstNumber && uploadedGstImage);
    const isFssaiDetailsValid = fssaiNumber && expiryDate && uploadedFssaiImage;
    setIsFormValid(
      isAccountNumberValid &&
      isReEnterAccountNumberValid &&
      isIfscCodeValid &&
      isAccountTypeValid &&
      isPanDetailsValid &&
      isFssaiDetailsValid &&
      isGstDetailsValid
    );
  };

  const handleNextClick = async () => {
    if (isFormValid) {
      console.log("After converting", expiryDate);

      const restaurantDocumentsData = {
        panNumber: panNumber,
        panFullName: fullName,
        gstNumber: gstNumber,
        gstAddress: address,
        fssaiNumber: fssaiNumber,
        fssaiValidTo: expiryDate ? expiryDate : null,
        bankAccountNumber: accountNumber,
        bankIfscCode: ifscCode,
        accountType: accountType,
      };

      localStorage.setItem("businessAddress", address);
      localStorage.setItem("gstNumber", gstNumber);
      localStorage.setItem("accountNumber", accountNumber);
      localStorage.setItem("fssaiNumber", fssaiNumber);
      try {
        const response = await updateRestaurantDocuments(
          restaurantDocumentsData,
          restaurantId
        );
        // Navigate to the contract page after successful submission
        navigate("/contract");
      } catch (error) {
        console.error("Error updating restaurant documents:", error);
      }
    } else {
    }
  };


  return (
    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f7f7f7">
      <div className="registration bg-gray-100 h-screen flex">
        <main className="pt-20 pb-20 flex w-full">
          <div className="content flex-1 overflow-y-auto bg-gray-100">
            <div className="container mx-auto p-6">
              {loading ? (
                <Skeleton height={50} width={250} borderRadius={8}></Skeleton>
              ) : (
                <h3 className="text-3xl font-bold">Restaurant Documents</h3>
              )}
              {/* PAN Details Section */}
              {verificationModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded shadow-lg">
                    <h2 className="text-xl font-bold mb-4">
                      Verification Status
                    </h2>
                    <p>{verificationMessage}</p>
                    <button
                      onClick={closeVerificationModal}
                      className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              <div className="bg-white p-6 rounded-lg shadow-md mt-5">
                {loading ? (
                  <Skeleton
                    height={100}
                    width={900}
                    borderRadius={8}
                  ></Skeleton>
                ) : (
                  <>
                    <h2 className="text-2xl font-bold mb-4">PAN details</h2>
                    <p className="text-gray-600 mb-6">
                      Enter the PAN details of the person or company who legally
                      owns the restaurant
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-3 items-end">
                      {/* PAN Number Input */}
                      <div className="col-span-12 md:col-span-4">
                        <label
                          htmlFor="panNumber"
                          className="block text-sm font-medium text-gray-700"
                        >
                          PAN number*
                        </label>
                        <input
                          type="text"
                          id="panNumber"
                          value={panNumber}
                          maxLength={10}
                          onChange={(e) => {
                            let inputValue = e.target.value
                              .toUpperCase()
                              .replace(/\s+/g, "");
                            if (inputValue?.length <= 5) {
                              inputValue = inputValue.replace(/[^A-Z]/g, "");
                            } else if (inputValue?.length <= 9) {
                              inputValue =
                                inputValue.slice(0, 5) +
                                inputValue.slice(5).replace(/[^0-9]/g, "");
                            } else {
                              inputValue =
                                inputValue.slice(0, 9) +
                                inputValue.slice(9).replace(/[^A-Z]/g, "");
                            }
                            setPanNumber(inputValue);
                          }}
                          className={`mt-1 block w-full p-2 border ${panInvalid ? "border-red-500" : "border-gray-300"
                            } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                        />
                      </div>

                      {/* Full Name Input */}
                      <div className="col-span-12 md:col-span-5">
                        <label
                          htmlFor="fullName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Full name as per PAN*
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          value={fullName}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              /^[A-Za-z\s]*$/.test(value) &&
                              value.length <= 30
                            ) {
                              setFullName(value.toUpperCase());
                            }
                          }}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>

                      {/* Verify Button */}
                      <div className="col-span-6 md:col-span-1 flex justify-center md:justify-start">
                        {panNumber?.length === 10 &&
                          fullName &&
                          uploadedPanImage && (
                            <button
                              onClick={async () => {
                                setIsPanVerifying(true); // Set verifying state
                                try {
                                  await handlePanVerify(); // Simulated API call
                                  setPanVerified(true); // Mark as verified
                                } catch (error) {
                                  console.error(
                                    "PAN verification failed:",
                                    error
                                  );
                                } finally {
                                  setIsPanVerifying(false); // Reset verifying state
                                }
                              }}
                              className={`mt-6 ${isPanVerifying
                                ? "bg-yellow-500 text-white cursor-not-allowed"
                                : "bg-green-500 text-white hover:bg-green-600"
                                } px-4 py-2 rounded`}
                              disabled={panVerified || isPanVerifying}
                            >
                              {isPanVerifying
                                ? "Verifying..." // Show Verifying state
                                : panVerified
                                  ? "Verified" // Show Verified state
                                  : "Verify"}
                            </button>
                          )}
                      </div>

                      {/* Pan Verified Message */}
                      <div className="col-span-6 md:col-span-2 mx-6 flex items-center justify-start">
                        {panVerified && (
                          <div className="text-green-500">
                            <span className="material-icons font-bold">
                              Pan Verified
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    {panInvalid && (
                      <p className="text-red-500 text-sm mb-2">
                        Invalid PAN number
                      </p>
                    )}

                    {/* File Upload Section */}
                    <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center">
                      {uploadedPanImage ? (
                        uploadedPanImage.endsWith(".pdf") ? (
                          <div className="flex items-center justify-evenly space-x-4">
                            <a
                              href={uploadedPanImage}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-green-500"
                            >
                              View Uploaded PDF
                            </a>
                            <button
                              className="top-0 right-1 text-red-500 bg-white rounded-full p-2 shadow-lg"
                              onClick={() =>
                                handleDeleteImage(
                                  () =>
                                    deletePanUrl(
                                      uploadedPanImage,
                                      restaurantId
                                    ),
                                  setUploadedPanImage,
                                  setPanVerificationStatus
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        ) : (
                          <div className="relative">
                            <img
                              src={uploadedPanImage}
                              alt="Uploaded"
                              className="mx-auto mb-4 max-h-48"
                            />
                            <button
                              className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                              onClick={() =>
                                handleDeleteImage(
                                  () =>
                                    deletePanUrl(
                                      uploadedPanImage,
                                      restaurantId
                                    ),
                                  setUploadedPanImage,
                                  setPanVerificationStatus
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        )
                      ) : (
                        <div className="flex flex-col justify-center items-center mb-4">
                          <div className="mb-2">
                            <input
                              type="file"
                              onChange={handlePanImageUpload}
                              className="mt-2 hidden"
                              id="pan-image-upload"
                            />
                            <label
                              htmlFor="pan-image-upload"
                              className="cursor-pointer"
                            >
                              <div className="upload-images-border border-dashed border-2 rounded-lg p-3 flex items-center justify-center text-gray-600">
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="camera-icon w-8 h-8 mb-1"
                                />
                              </div>
                            </label>
                          </div>
                          <p className="text-green-500 font-bold">
                            Upload your PAN
                          </p>
                          <p className="text-gray-600">
                            jpeg, png or pdf formats up-to 5MB
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        onClick={() => setIsPanModalOpen(true)}
                        className="text-green-500 underline"
                      >
                        Guidelines to upload PAN
                      </button>
                    </div>
                  </>
                )}
              </div>
              <UploadGuidelinesModal
                isOpen={isPanModalOpen}
                onClose={() => setIsPanModalOpen(false)}
              />
              <div className="bg-white rounded-lg shadow-md mt-6">
                <div className="container mx-auto p-6">
                  <div>
                    {loading ? (
                      <Skeleton
                        height={50}
                        width={900}
                        borderRadius={8}
                      ></Skeleton>
                    ) : (
                      <>
                        <h4 className="text-xl font-bold mb-4">
                          Full address of your registered business*
                        </h4>
                        <input
                          type="text"
                          id="address"
                          value={address}
                          onChange={(e) => {
                            // Only update if length is less than or equal to 50
                            if (e.target.value.length <= 150) {
                              setAddress(e.target.value);
                            }
                          }}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                        <p className="text-sm text-gray-500 mt-1">
                          {address?.length}/150 characters
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* GST Details Section */}
              <div className="bg-white p-6 rounded-lg shadow-md mt-6">
                {loading ? (
                  <Skeleton height={80} width={900} borderRadius={8}></Skeleton>
                ) : (
                  <>
                    <p className="text-sm text-gray-600 mb-2">
                      {" "}
                      Note: Please first enter and verify all the PAN details
                      above.{" "}
                    </p>
                    <h2 className="text-2xl font-bold mb-2">
                      {" "}
                      GST details (if applicable){" "}
                    </h2>
                    <p className="text-gray-600 mb-6">
                      {" "}
                      This should be linked to the PAN provided earlier for tax
                      calculations{" "}
                    </p>
                    <p className="mb-4">Are you GST registered?</p>
                    <div className="flex items-center mb-6">
                      <label className="flex items-center mr-4">
                        <input
                          type="radio"
                          name="gstRegistered"
                          value="yes"
                          checked={isGstRegistered === "yes"}
                          onChange={() => setIsGstRegistered("yes")}
                          className="form-radio text-blue-500"
                        />
                        <span className="ml-2">Yes</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="gstRegistered"
                          value="no"
                          checked={isGstRegistered === "no"}
                          onChange={() => setIsGstRegistered("no")}
                          className="form-radio text-blue-500"
                        />
                        <span className="ml-2">No</span>
                      </label>
                    </div>
                    {isGstRegistered === "yes" && (
                      <>
                        <div className="flex-1 mt-4">
                          <label
                            htmlFor="gstNumber"
                            className="block text-sm font-medium text-gray-700 mr-4"
                          >
                            {" "}
                            GST Number*{" "}
                          </label>
                          <div className="flex items-center">
                            <input
                              type="text"
                              id="gstNumber"
                              value={gstNumber}
                              maxLength={15}
                              onChange={(e) => {
                                let inputValue = e.target.value
                                  .toUpperCase()
                                  .replace(/\s+/g, ""); // Remove whitespace and uppercase
                                if (inputValue?.length <= 2) {
                                  inputValue = inputValue.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // First 2 characters should be numbers (state code)
                                } else if (inputValue?.length <= 12) {
                                  inputValue =
                                    inputValue.slice(0, 2) +
                                    inputValue
                                      .slice(2)
                                      .replace(/[^A-Z0-9]/g, ""); // Next 10 characters: PAN format
                                } else if (inputValue?.length === 13) {
                                  inputValue =
                                    inputValue.slice(0, 12) +
                                    inputValue
                                      .slice(12)
                                      .replace(/[^A-Z0-9]/g, ""); // 13th character: Number or Alphabet
                                } else if (inputValue?.length === 14) {
                                  inputValue =
                                    inputValue.slice(0, 13) +
                                    inputValue.slice(13).replace(/[^Z]/g, ""); // 14th character should always be 'Z'
                                } else if (inputValue?.length === 15) {
                                  inputValue =
                                    inputValue.slice(0, 14) +
                                    inputValue
                                      .slice(14)
                                      .replace(/[^A-Z0-9]/g, ""); // 15th character: Checksum digit (alphabet or number)
                                }
                                setGstNumber(inputValue); // Set the formatted value
                              }}
                              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            />

                            <div className="col-span-6 md:col-span-1 flex justify-center md:justify-start">
                              {gstNumber?.length === 15 && uploadedGstImage && (
                                <button
                                  onClick={async () => {
                                    setIsGstVerifying(true); // Start verifying state
                                    try {
                                      await handleGstVerify(); // Perform the verification API call
                                      setGstVerified(true); // Set verified status to true on success
                                    } catch (error) {
                                      console.error(
                                        "GST verification failed:",
                                        error
                                      );
                                      setGstVerified(false); // Handle failure by resetting verification status
                                    } finally {
                                      setIsGstVerifying(false); // Reset verifying state
                                    }
                                  }}
                                  className={`ml-4 text-white px-4 py-2 rounded ${isGstVerifying
                                    ? "bg-yellow-500 hover:bg-yellow-600"
                                    : gstVerified
                                      ? "bg-green-500 hover:bg-green-600 cursor-not-allowed"
                                      : "bg-green-500 hover:bg-green-600"
                                    }`}
                                  disabled={gstVerified || isGstVerifying} // Disable button while verifying or if already verified
                                >
                                  {isGstVerifying
                                    ? "Verifying"
                                    : gstVerified
                                      ? "Verified"
                                      : "Verify"}
                                </button>
                              )}
                            </div>

                            <div className="col-span-6 md:col-span-2 mx-6 flex items-center justify-start">
                              {gstVerified && (
                                <div className="text-green-500">
                                  <span className="material-icons font-bold">
                                    {" "}
                                    GST Verified{" "}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {gstInvalid && (
                          <p
                            className="text-red-500 mt-2 text-sm mb-2"
                            disabled={address && gstNumber && uploadedGstImage}
                          >
                            Invalid GST number & Check the Address
                          </p>
                        )}
                        <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mt-6">
                          {uploadedGstImage ? (
                            uploadedGstImage.endsWith(".pdf") ? (
                              <div className="flex items-center justify-evenly space-x-4">
                                <a
                                  href={uploadedGstImage}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-green-500"
                                >
                                  View Uploaded PDF
                                </a>
                                <button
                                  className="top-0 right-1 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                  onClick={() =>
                                    handleDeleteImage(
                                      () =>
                                        deleteGstUrl(
                                          uploadedGstImage,
                                          restaurantId
                                        ),
                                      setUploadedGstImage,
                                      setGstVerificationStatus
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                            ) : (
                              <div className="relative">
                                <img
                                  src={uploadedGstImage}
                                  alt="Uploaded"
                                  className="mx-auto mb-4 max-h-48"
                                />
                                <button
                                  className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                  onClick={() =>
                                    handleDeleteImage(
                                      () =>
                                        deleteGstUrl(
                                          uploadedGstImage,
                                          restaurantId
                                        ),
                                      setUploadedGstImage,
                                      setGstVerificationStatus
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                            )
                          ) : (
                            <div className="flex flex-col justify-center items-center mb-4">
                              <div className="mb-2">
                                <input
                                  type="file"
                                  onChange={handleGstImageUpload}
                                  className="mt-2 hidden"
                                  id="gst-image-upload"
                                />
                                <label
                                  htmlFor="gst-image-upload"
                                  className="cursor-pointer"
                                >
                                  <div className="upload-images-border border-dashed border-2 rounded-lg p-3 flex items-center justify-center text-gray-600">
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      className="camera-icon w-8 h-8 mb-1"
                                    />
                                  </div>
                                </label>
                              </div>
                              <p className="text-green-500 font-bold">
                                Upload your GST Certificate
                              </p>
                              <p className="text-gray-600">
                                jpeg, png or pdf formats up-to 5MB
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="mt-4 text-center">
                          <button
                            onClick={() => setIsGSTModalOpen(true)}
                            className="text-green-500 underline"
                          >
                            {" "}
                            Guidelines to upload GST{" "}
                          </button>
                        </div>
                        <UploadGstGuidelines
                          isOpen={isGSTModalOpen}
                          onClose={() => setIsGSTModalOpen(false)}
                        />
                      </>
                    )}
                  </>
                )}
              </div>{" "};

              {/* FSSAI Details Section */}
              <div className="container mx-auto mt-5">
                {loading ? (
                  <Skeleton height={80} width={900} borderRadius={8}></Skeleton>
                ) : (
                  <>
                    <h1 className="text-3xl font-bold mb-4">FSSAI details</h1>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-2xl font-bold mb-4">FSSAI details</h2>
                      <p className="text-gray-600 mb-6">
                        This is required to comply with regulations on food
                        safety
                      </p>
                      <div className="bg-blue-50 p-4 rounded-lg mb-6">
                        <h3 className="font-bold text-lg mb-2">
                          FSSAI requirements
                        </h3>
                        <ul className="list-disc list-inside">
                          <li>
                            The name on the FSSAI certificate should match
                            either the restaurant's name or the name on the PAN
                            card.
                          </li>
                          <li>
                            The address on the FSSAI certificate should match
                            the restaurant's address.
                          </li>
                        </ul>
                      </div>
                      <div className="grid grid-cols-1 gap-6 mb-6">
                        <div className="flex-1">
                          <label
                            htmlFor="fssaiNumber"
                            className="block text-sm font-medium text-gray-700 mr-4"
                          >
                            FSSAI Number*
                          </label>
                          <div className="flex items-center">
                            <input
                              type="text"
                              id="fssaiNumber"
                              value={fssaiNumber}
                              maxLength={14} // Restrict input to 14 characters
                              onChange={(e) => {
                                let inputValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                ); // Remove any non-numeric characters
                                if (inputValue?.length <= 14) {
                                  setFssaiNumber(inputValue); // Set the formatted value
                                }
                              }}
                              className="mt-1 block w-1/2 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            />
                            {fssaiNumber?.length === 14 &&
                              uploadedFssaiImage && (
                                <button
                                  onClick={async () => {
                                    setIsFssaiVerifying(true); // Start the verifying state
                                    try {
                                      await handleFssaiVerify(); // Perform the verification API call
                                      setFssaiVerified(true); // Set verified status to true on success
                                    } catch (error) {
                                      console.error(
                                        "FSSAI verification failed:",
                                        error
                                      );
                                      setFssaiVerified(false); // Handle failure by resetting verification status
                                    } finally {
                                      setIsFssaiVerifying(false); // Reset the verifying state
                                    }
                                  }}
                                  className={`ml-4 text-white px-4 py-2 rounded ${isFssaiVerifying
                                    ? "bg-yellow-500 hover:bg-yellow-600"
                                    : fssaiVerified
                                      ? "bg-green-500 hover:bg-green-600 cursor-not-allowed"
                                      : "bg-green-500 hover:bg-green-600"
                                    }`}
                                  disabled={fssaiVerified || isFssaiVerifying} // Disable button while verifying or if already verified
                                >
                                  {isFssaiVerifying
                                    ? "Verifying"
                                    : fssaiVerified
                                      ? "Verified"
                                      : "Verify"}
                                </button>
                              )}

                            <div className="col-span-6 md:col-span-2 mx-6 flex items-center justify-start">
                              {fssaiVerified && (
                                <div className="text-green-500">
                                  <span className="material-icons font-bold">
                                    {" "}
                                    FSSAI Verified{" "}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {fssaiInvalid && (
                          <p className="text-red-500 text-sm mt-1">
                            Invalid FSSAI number
                          </p>
                        )}
                        <div className="flex-1 relative">
                          <label
                            htmlFor="expiryDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {" "}
                            Expiry date*{" "}
                          </label>
                          <div className="mt-1 relative">
                            <DatePicker
                              ref={datepickerRef}
                              selected={expiryDate}
                              onChange={(date) => {
                                setExpiryDate(convertTimeFormat(date));
                              }}
                              dateFormat="dd/MM/yyyy"
                              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            />
                            <FaCalendarAlt
                              className="absolute top-3 left-44 text-gray-400 cursor-pointer"
                              onClick={() => datepickerRef.current.setFocus()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mb-6">
                        {uploadedFssaiImage ? (
                          uploadedFssaiImage.endsWith(".pdf") ? (
                            <div className="flex items-center justify-evenly space-x-4">
                              <a
                                href={uploadedFssaiImage}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-green-500"
                              >
                                View Uploaded PDF
                              </a>
                              <button
                                className="top-0 right-1 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                onClick={() =>
                                  handleDeleteImage(
                                    () =>
                                      deleteFssaiUrl(
                                        uploadedFssaiImage,
                                        restaurantId
                                      ),
                                    setUploadedFssaiImage,
                                    setFssaiVerificationStatus
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          ) : (
                            <div className="relative">
                              <img
                                src={uploadedFssaiImage}
                                alt="Uploaded"
                                className="mx-auto mb-4 max-h-48"
                              />
                              <button
                                className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                                onClick={() =>
                                  handleDeleteImage(
                                    () =>
                                      deleteFssaiUrl(
                                        uploadedFssaiImage,
                                        restaurantId
                                      ),
                                    setUploadedFssaiImage,
                                    setFssaiVerificationStatus
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          )
                        ) : (
                          <div className="flex flex-col justify-center items-center mb-4">
                            <div className="mb-2">
                              <input
                                type="file"
                                onChange={handleFssaiImageUpload}
                                className="mt-2 hidden"
                                id="fssai-image-upload"
                              />
                              <label
                                htmlFor="fssai-image-upload"
                                className="cursor-pointer"
                              >
                                <div className="upload-images-border border-dashed border-2 rounded-lg p-3 flex items-center justify-center text-gray-600">
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="camera-icon w-8 h-8 mb-1"
                                  />
                                </div>
                              </label>
                            </div>
                            <p className="text-green-500 font-bold">
                              Upload your Fssai License
                            </p>
                            <p className="text-gray-600">
                              jpeg, png or pdf formats up-to 5MB
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="mt-4 text-center">
                        <button
                          onClick={() => setIsFssaiModalOpen(true)}
                          className="text-green-500 underline"
                        >
                          FSSAI guidelines to upload
                        </button>
                      </div>
                    </div>
                    <UploadFssaiGuidelines
                      isOpen={isFssaiModalOpen}
                      onClose={() => setIsFssaiModalOpen(false)}
                    />
                  </>
                )}
              </div>



              {/* <div className="container mt-5">
                {loading ? (
                  <Skeleton height={80} width={900} borderRadius={8}></Skeleton>
                ) : (
                  <>
                    <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                      Bank account details
                    </h1>
                    <p className="text-gray-600 mb-4">
                      This is where {APP_NAME} will deposit your earnings
                    </p>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                        {/* Account Number */}
              {/* <div>
                          <label
                            htmlFor="accountNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Bank account number*
                          </label>
                          <input
                            type="text"
                            id="accountNumber"
                            value={accountNumber}
                            onChange={handleAccountNumberChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {accountNumber?.length > 0 &&
                            (accountNumber?.length < 9 ||
                              accountNumber?.length > 18) && (
                              <p className="text-red-500 text-sm mt-1">
                                Invalid account number
                              </p>
                            )}
                        </div> */}

              {/* Re-enter Account Number */}
              {/* <div>
                          <label
                            htmlFor="reEnterAccountNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Re-enter bank account number*
                          </label>
                          <input
                            type="text"
                            id="reEnterAccountNumber"
                            value={reEnterAccountNumber}
                            onChange={handleReEnterAccountNumberChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {reEnterAccountNumber?.length > 0 &&
                            reEnterAccountNumber !== accountNumber && (
                              <p className="text-red-500 text-sm mt-1">
                                Account numbers do not match
                              </p>
                            )}
                        </div> */}

              {/* IFSC Code */}
              {/* <div>
                          <label
                            htmlFor="ifscCode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Enter IFSC Code*
                          </label>
                          <input
                            type="text"
                            id="ifscCode"
                            value={ifscCode}
                            onChange={handleIfscCodeChange}
                            maxLength={11} // Restrict input length to 11 characters
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {error && (
                            <p className="text-red-500 text-sm mt-1">{error}</p>
                          )}
                        </div> */}

              {/* Account Type */}
              {/* <div className="relative">
                          <label
                            htmlFor="accountType"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Account Type
                          </label>
                          <select
                            id="accountType"
                            value={accountType}
                            onChange={handleAccountTypeChange}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm overflow-hidden"
                            disabled={bankVerificationStatus === "verified"}
                          >
                            <option value="">Select Account Type</option>
                            <option value="Savings">Savings</option>
                            <option value="Current">Current</option>
                          </select>
                          {showAccountTypeError && accountType === "" && (
                            <p className="text-red-500 text-sm mt-1">
                              Please select account type
                            </p>
                          )}
                        </div>
                      </div> */}

              {/* <button
                        className={`mt-6 w-full py-2 rounded flex justify-center items-center ${bankVerificationStatus === "verifying"
                          ? "bg-yellow-500 text-white cursor-not-allowed"
                          : bankVerificationStatus === "verified"
                            ? "bg-green-500 text-white cursor-not-allowed"
                            : "bg-green-500 text-white hover-bg-green-600"
                          }`}
                        onClick={async () => {
                          setBankVerificationStatus("verifying"); // Start verifying state
                          try {
                            await handleBankVerify(); // Perform verification API call
                            setBankVerificationStatus("verified"); // Update status on success
                          } catch (error) {
                            console.error("Bank verification failed:", error);
                            setBankVerificationStatus("error"); // Handle verification failure
                          }
                        }}
                        disabled={
                          bankVerificationStatus === "verifying" ||
                          bankVerificationStatus === "verified"
                        } // Disable during verification or if already verified
                      >
                        {bankVerificationStatus === "verifying"
                          ? "Verifying..."
                          : bankVerificationStatus === "verified"
                            ? "Verified bank account details"
                            : "Verify bank account details"}
                      </button> */}

              {/* Verified Message */}
              {/* {bankVerificationStatus === "verified" && (
                        <p className="text-green-600 text-sm mt-4">
                          Account details verified successfully!
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div> */}


              <div className="container mt-5">
                {loading ? (
                  <Skeleton height={80} width={900} borderRadius={8}></Skeleton>
                ) : (
                  <>
                    <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                      Bank account details
                    </h1>
                    <p className="text-gray-600 mb-4">
                      This is where {APP_NAME} will deposit your earnings
                    </p>
                    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                        {/* Account Number */}
                        <div>
                          <label
                            htmlFor="accountNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Bank account number*
                          </label>
                          <input
                            type="text"
                            id="accountNumber"
                            value={accountNumber}
                            onChange={handleAccountNumberChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {accountNumber?.length > 0 &&
                            (accountNumber?.length < 9 ||
                              accountNumber?.length > 18) && (
                              <p className="text-red-500 text-sm mt-1">
                                Invalid account number
                              </p>
                            )}
                        </div>

                        {/* Re-enter Account Number */}
                        <div>
                          <label
                            htmlFor="reEnterAccountNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Re-enter bank account number*
                          </label>
                          <input
                            type="text"
                            id="reEnterAccountNumber"
                            value={reEnterAccountNumber}
                            onChange={handleReEnterAccountNumberChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {reEnterAccountNumber?.length > 0 &&
                            reEnterAccountNumber !== accountNumber && (
                              <p className="text-red-500 text-sm mt-1">
                                Account numbers do not match
                              </p>
                            )}
                        </div>

                        {/* IFSC Code */}
                        <div>
                          <label
                            htmlFor="ifscCode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Enter IFSC Code*
                          </label>
                          <input
                            type="text"
                            id="ifscCode"
                            value={ifscCode}
                            onChange={handleIfscCodeChange}
                            maxLength={11} // Restrict input length to 11 characters
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                            disabled={bankVerificationStatus === "verified"}
                          />
                          {error && (
                            <p className="text-red-500 text-sm mt-1">{error}</p>
                          )}
                        </div>

                        {/* Account Type */}
                        <div className="relative">
                          <label
                            htmlFor="accountType"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Account Type
                          </label>
                          <select
                            id="accountType"
                            value={accountType}
                            onChange={handleAccountTypeChange}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm overflow-hidden"
                            disabled={bankVerificationStatus === "verified"}
                          >
                            <option value="">Select Account Type</option>
                            <option value="Savings">Savings</option>
                            <option value="Current">Current</option>
                          </select>
                          {showAccountTypeError && accountType === "" && (
                            <p className="text-red-500 text-sm mt-1">
                              Please select account type
                            </p>
                          )}
                        </div>
                      </div>

                      <button
                        className={`mt-6 w-full py-2 rounded flex justify-center items-center ${bankVerificationStatus === "verifying"
                          ? "bg-yellow-500 text-white cursor-not-allowed"
                          : bankVerificationStatus === "verified"
                            ? "bg-green-500 text-white cursor-not-allowed"
                            : "bg-green-500 text-white hover-bg-green-600"
                          }`}
                        onClick={async () => {
                          setIsBankVerifying(true); // Set verifying state to true
                          setBankVerificationStatus("verifying"); // Start verifying state
                          try {
                            await handleBankVerify(); // Perform verification API call
                            setBankVerificationStatus("verified"); // Update status on success
                          } catch (error) {
                            console.error("Bank verification failed:", error);
                            setBankVerificationStatus("error"); // Handle verification failure
                          } finally {
                            setIsBankVerifying(false); // Reset verifying state
                          }
                        }}
                        disabled={
                          bankVerificationStatus === "verifying" ||
                          bankVerificationStatus === "verified"
                        } // Disable during verification or if already verified
                      >
                        {bankVerificationStatus === "verifying"
                          ? "Verifying..."
                          : bankVerificationStatus === "verified"
                            ? "Verified bank account details"
                            : "Verify bank account details"}
                      </button>

                      {/* Verified Message */}
                      {bankVerificationStatus === "verified" && (
                        <p className="text-green-600 text-sm mt-4">
                          Account details verified successfully!
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>







            </div>
          </div>
        </main>
        <footer className="fixed bottom-0 w-2/3">
          <div className="flex flex-row justify-end items-center p-4  sm:space-x-4 space-x-2 w-full">
            {/* Save As Draft Button */}
            <div className="flex flex-col items-center md:items-start">
              <button
                className={
                  "bg-slate-500 text-white py-2 px-10 sm:px-6 rounded w-auto flex justify-center items-center  cursor-pointer opacity-100"
                }
                // disabled={!isFormValid}
                onClick={handleSaveAsDraft}
              >
                Save As Draft
              </button>

              {/* Success Message */}
              {saveMessage && (
                <p className="text-green-600 mt-2 text-center md:text-left">
                  {saveMessage}
                </p>
              )}
            </div>

            <button
              className={`bg-green-600 text-white py-2 px-6 sm:px-6 rounded w-auto flex justify-center items-center ${isFormValid
                ? "cursor-pointer opacity-100"
                : "cursor-not-allowed opacity-50"
                }`}
              disabled={!isFormValid}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </footer>

        {showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}

        {showReferModal && (
          <ReferrerModal
            toggleReferModal={toggleReferModal}
            onClickSuccess={() => {
              toggleReferModal();
            }}
          />
        )}

        {showLogoutModal && (
          <LogoutModel
            onClickLogout={confirmLogout}
            onClickCancel={() => setShowLogoutModal(false)}
          />
        )}
      </div>
    </SkeletonTheme>
  );
};

export default RestaurantDocuments;


