import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import GoogleIcon from '../images/google-icon.svg';
import Otp from './Otp';
import Service from './Service';
import { sendOtp } from '../services/AuthApi';
import { handleError } from '../services/errorHandler';

const Login = ({ isOpen, onClose }) => {
    const [loginState, setLoginState] = useState({
        currentPage: 'LOGIN',
        phoneNumber: ''
    });

    // To store phone number in localStorage
    useEffect(() => {
        if (loginState.phoneNumber) {
            localStorage.setItem("phoneNumber", `+91${loginState.phoneNumber}`);
        }
    }, [loginState.phoneNumber]);

    if (!isOpen && loginState.currentPage === 'LOGIN') {
        return null;
    }

    // To send OTP and for transition to OTP page
    const handleSendOTP = async (e) => {
        e.preventDefault();
        const phoneNumberPattern = /^\d{10}$/;
        const mode = "send";
        if (!phoneNumberPattern.test(loginState.phoneNumber)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        try {
            const response = await sendOtp(`+91${loginState.phoneNumber}`, mode);
            updateLoginState('currentPage', 'OTP');

            const { otp, otpExpiry, otpResendWindow, totalAttempts } = response;

            localStorage.setItem("otpRetriesLeft", totalAttempts)

            // Store OTP details in localStorage
            localStorage.setItem("otpDetails", JSON.stringify({
                otp: otp,
                otpExpiry: Date.now() + otpExpiry * 1000, // current time + expiry time (5 minutes)
                otpResendWindow: Date.now() + otpResendWindow * 1000, // 7 minutes window
                totalAttempts: totalAttempts
            }));
            localStorage.setItem("uniqueAttemptId", response.uniqueAttemptId);

        } catch (error) {
            handleError(error);
        }
    };

    // To handle phone number change
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value
        // Restricts input to digits only (prevents entering alphabets or special characters)
        if (/^\d*$/.test(value)) {
            updateLoginState('phoneNumber', value);
        }
    };

    // Helper function to keep updates concise
    const updateLoginState = (key, value) => {
        setLoginState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    // Close the modal and reset to login page
    const handleClose = () => {
        updateLoginState('currentPage', 'LOGIN');
        onClose();
    };

    // Transition to Service page after OTP timer ends
    const handleTimerEnd = () => {
        updateLoginState('currentPage', 'SERVICE');
    };

    return (
        <>
            {/* Renders the current page based on loginState */}
            {loginState.currentPage === 'SERVICE' ? (
                <Service onClose={handleClose} />
            ) : loginState.currentPage === 'OTP' ? (
                <Otp onClose={handleClose} />
            ) : (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50 px-4">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold">Login</h2>
                            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        <form className="space-y-4" onSubmit={handleSendOTP}>
                            <div className="flex items-center border rounded px-3 py-2">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png" alt="India Flag" className="w-6 h-4 mr-2" />
                                <span className="text-gray-700">+91</span>
                                <input
                                    type="tel"
                                    placeholder="Phone"
                                    minLength="10"
                                    maxLength="10"
                                    className="ml-2 outline-none"
                                    value={loginState.phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold rounded py-2">Send One Time Password</button>
                        </form>
                        <div className="flex items-center my-4">
                            <hr className="flex-grow border-gray-300" />
                            <span className="mx-2 text-gray-500">Or</span>
                            <hr className="flex-grow border-gray-300" />
                        </div>
                        <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded">
                            <FontAwesomeIcon icon={faEnvelope} className="text-red-600 mr-2" />Continue with Email
                        </button>
                        <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded mt-2">
                            <img src={GoogleIcon} alt="Google" className="mr-2 w-5 h-5" />
                            Sign in with Google
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Login;


// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
// import GoogleIcon from "../images/google-icon.svg";
// import Otp from "./Otp";
// import Service from "./Service";
// import { sendOtp } from "../services/AuthApi";
// import { handleError } from "../services/errorHandler";

// const Login = ({ isOpen, onClose }) => {
//     const [loginState, setLoginState] = useState({
//         currentPage: "LOGIN",
//         phoneNumber: "",
//     });

//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         // Simulate loading period for 2 seconds
//         const timeout = setTimeout(() => {
//             setLoading(false);
//         }, 2000);

//         return () => clearTimeout(timeout);
//     }, []);

//     useEffect(() => {
//         if (loginState.phoneNumber) {
//             localStorage.setItem("phoneNumber", `+91${loginState.phoneNumber}`);
//         }
//     }, [loginState.phoneNumber]);

//     if (!isOpen && loginState.currentPage === "LOGIN") {
//         return null;
//     }

//     const handleSendOTP = async (e) => {
//         e.preventDefault();
//         const phoneNumberPattern = /^\d{10}$/;
//         const mode = "send";
//         if (!phoneNumberPattern.test(loginState.phoneNumber)) {
//             alert("Please enter a valid 10-digit phone number.");
//             return;
//         }

//         try {
//             setLoading(true); // Start loading while sending OTP
//             const response = await sendOtp(`+91${loginState.phoneNumber}`, mode);
//             updateLoginState("currentPage", "OTP");

//             const { otp, otpExpiry, otpResendWindow, totalAttempts } = response;

//             localStorage.setItem("otpRetriesLeft", totalAttempts);

//             localStorage.setItem(
//                 "otpDetails",
//                 JSON.stringify({
//                     otp: otp,
//                     otpExpiry: Date.now() + otpExpiry * 1000,
//                     otpResendWindow: Date.now() + otpResendWindow * 1000,
//                     totalAttempts: totalAttempts,
//                 })
//             );
//             localStorage.setItem("uniqueAttemptId", response.uniqueAttemptId);
//         } catch (error) {
//             handleError(error);
//         } finally {
//             setLoading(false); // Stop loading after OTP is sent
//         }
//     };

//     const handlePhoneNumberChange = (e) => {
//         const value = e.target.value;
//         if (/^\d*$/.test(value)) {
//             updateLoginState("phoneNumber", value);
//         }
//     };

//     const updateLoginState = (key, value) => {
//         setLoginState((prevState) => ({
//             ...prevState,
//             [key]: value,
//         }));
//     };

//     const handleClose = () => {
//         updateLoginState("currentPage", "LOGIN");
//         onClose();
//     };

//     return (
//         <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f7f7f7">
//             {loginState.currentPage === "SERVICE" ? (
//                 <Service onClose={handleClose} />
//             ) : loginState.currentPage === "OTP" ? (
//                 <Otp onClose={handleClose} />
//             ) : (
//                 <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50 px-4">
//                     <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
//                         <div className="flex justify-between items-center mb-4">
//                             <h2 className="text-xl font-semibold">
//                                 {loading ? <Skeleton width={100} /> : "Login"}
//                             </h2>
//                             <button
//                                 onClick={onClose}
//                                 className="text-gray-500 hover:text-gray-700"
//                             >
//                                 <FontAwesomeIcon icon={faTimes} />
//                             </button>
//                         </div>
//                         {loading ? (
//                             <>
//                                 <Skeleton height={50} className="mb-4" />
//                                 <Skeleton height={50} width={300} className="mb-4" />
//                                 <Skeleton height={40} width={300} />
//                             </>
//                         ) : (
//                             <form className="space-y-4" onSubmit={handleSendOTP}>
//                                 <div className="flex items-center border rounded px-3 py-2">
//                                     <img
//                                         src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
//                                         alt="India Flag"
//                                         className="w-6 h-4 mr-2"
//                                     />
//                                     <span className="text-gray-700">+91</span>
//                                     <input
//                                         type="tel"
//                                         placeholder="Phone"
//                                         minLength="10"
//                                         maxLength="10"
//                                         className="ml-2 outline-none"
//                                         value={loginState.phoneNumber}
//                                         onChange={handlePhoneNumberChange}
//                                         required
//                                     />
//                                 </div>
//                                 <button
//                                     type="submit"
//                                     className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold rounded py-2"
//                                 >
//                                     Send One Time Password
//                                 </button>
//                             </form>
//                         )}
//                         <div className="flex items-center my-4">
//                             <hr className="flex-grow border-gray-300" />
//                             <span className="mx-2 text-gray-500">Or</span>
//                             <hr className="flex-grow border-gray-300" />
//                         </div>
//                         {loading ? (
//                             <Skeleton height={50} width={300} className="mb-4" />
//                         ) : (
//                             <>
//                                 <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded">
//                                     <FontAwesomeIcon
//                                         icon={faEnvelope}
//                                         className="text-red-600 mr-2"
//                                     />
//                                     Continue with Email
//                                 </button>
//                                 <button className="w-full border border-gray-300 hover:bg-gray-100 text-gray-700 font-semibold flex items-center justify-center py-2 rounded mt-2">
//                                     <img
//                                         src={GoogleIcon}
//                                         alt="Google"
//                                         className="mr-2 w-5 h-5"
//                                     />
//                                     Sign in with Google
//                                 </button>
//                             </>
//                         )}
//                     </div>
//                 </div>
//             )}
//         </SkeletonTheme>
//     );
// };

// export default Login;
